import { FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import styled from 'styled-components';

export const FaqsWrapperDesktop = styled.div`
	background-color: ${({ theme }) => theme.colors.white.base};
	padding: 20px;
	column-count: 2;
	column-gap: 16px;

	& > h3 {
		break-before: column;
	}
`;

export const FaqsListDesktop = styled.ul`
	& > li {
		margin-bottom: 8px;
		color: ${({ theme }) => theme.colors.primary.light};
		${FontMixin({ size: 'medium', weight: 'light', height: 'base' })}
	}
`;
