import { Margin } from '@babylon/ui-kit-styles/common/mixins/logical.styled';
import { css } from 'styled-components';

import type { HomeStyles } from '../types';

const liverpoolSearcherContainerStyles: HomeStyles = {
	SearcherContainer: () => css`
		${Margin({ bottom: '48px' })}
	`,
};

export default liverpoolSearcherContainerStyles;
