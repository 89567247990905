import Icon from '@babylon/ui-kit-base/components/icons/icon';
import type { FC } from 'react';
import { Children } from 'react';

import type { WeatherForecastProps } from '../../../../types';
import { ParagraphContent, ParagraphTitle } from '../../../paragraph';
import * as S from './styled';

const WeatherForecastDesktop: FC<WeatherForecastProps> = ({ description, tips, weather }) => (
	<S.WeatherForecastWrapperDesktop data-testid='WeatherForecastDesktop'>
		<S.WeatherForecastInfo>
			{description?.title && <ParagraphTitle tag='h3' content={description.title} />}
			{description?.text && <ParagraphContent tag='p' content={description.text} />}
			<S.WeatherForecastTips data-testid='WeatherForecastTipsDesktop'>
				{Children.toArray(
					tips?.map(tip => (
						<li>
							<Icon className='nico-check' />
							{tip.content}
						</li>
					))
				)}
			</S.WeatherForecastTips>
		</S.WeatherForecastInfo>

		<S.WeatherForecastCalendar data-testid='WeatherForecastCalendar'>
			{Children.toArray(
				weather?.map(temperature => (
					<S.WeatherForecastTemperatureDesktop>
						<span className='temperature-month'>{temperature.month}</span>
						<span className='temperature-max'>{temperature.max}</span>
						<span className='temperature-min'>{temperature.min}</span>
					</S.WeatherForecastTemperatureDesktop>
				))
			)}
		</S.WeatherForecastCalendar>
	</S.WeatherForecastWrapperDesktop>
);

export default WeatherForecastDesktop;
