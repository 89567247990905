import type { FC } from 'react';

import type { TabTitleProps } from './types';

const TabTitle: FC<TabTitleProps> = ({ title, link }) => (
	<div data-testid='TabTitle'>
		{link && <a data-testid='link' href={link} title={title} />}
		<span>{title}</span>
	</div>
);

export default TabTitle;
