import Banners from '@babylon/ui-kit-banners/components/banners';
import Image from '@babylon/ui-kit-base/components/multimedia/image';
import CarouselHeader from '@babylon/ui-kit-carousels/components/carousel-header/index';
import CarouselOffersCta from '@babylon/ui-kit-carousels/components/carousel-offers-cta/index';
import CarouselProduct from '@babylon/ui-kit-carousels/components/carousel-product';
import Breadcrumbs from '@babylon/ui-kit-common/components/others/breadcrumbs';
import Comarketing from '@babylon/ui-kit-common/components/others/comarketing';
import HeaderBannerSimple from '@babylon/ui-kit-common/components/others/header-banner-simple';
import ImagesList from '@babylon/ui-kit-common/components/others/images-list';
import LinksSEO from '@babylon/ui-kit-common/components/others/links-seo';
import TabsCarouselProductCard from '@babylon/ui-kit-common/components/others/tabs-carousel-product-card';
import withHydrationOnDemand from '@babylon/ui-kit-hocs/hydration-on-demand/withHydrationOnDemand';
import SearcherFerryHotelMobile from '@babylon/ui-kit-searchers/components/searchers/searcher-ferry-hotel/views/mobile';
import SearcherFlightHotelMobile from '@babylon/ui-kit-searchers/components/searchers/searcher-flight-hotel/views/mobile';
import SearcherMultiPak from '@babylon/ui-kit-searchers/components/searchers/searcher-multi-pak';
import SearcherTabs from '@babylon/ui-kit-searchers/components/searchers/searcher-tabs';
import type { SearcherTabsProps } from '@babylon/ui-kit-searchers/components/searchers/searcher-tabs/types';
import SearcherTouMobile from '@babylon/ui-kit-searchers/components/searchers/searcher-tou/views/mobile';
import SearcherTrainHotelMobile from '@babylon/ui-kit-searchers/components/searchers/searcher-train-hotel/views/mobile';
import CardsScroll from '@babylon/ui-kit-structures/components/others/cards-scroll';
import LinksBar from '@babylon/ui-kit-structures/components/others/links-bar/views/desktop';
import FeaturesPackagesMobile from '@babylon/ui-kit-wrappers/components/packages/features';
import TextHTMLCollapsePackagesMobile from '@babylon/ui-kit-wrappers/components/packages/text-html-collapse';
import type { ComponentProps } from 'react';

import CarouselList from '@/components/carousel-list';
import CarouselTravelCard from '@/components/circuits/carousel-travel-card';
import DestinationGuide from '@/components/destination-guide';
import ProductLei from '@/components/product-lei';

// order
export const DEFAULT_ORDER_MOBILE = [
	'--DEFAULT_ORDER_MOBILE--',
	'headerCarousel',
	'searcherPak',
	'searcherFlight',
	'searcherFerry',
	'searcherTrain',
	'searcherTabsRxjs',
	'breadcrumbs',
	'productCardTabs',
	'productTTOO',
	'productLei',
	'carouselList',
	'comarketing',
	'travelSafe',
	'features',
	'carouselOffers',
	'carouselSimple',
	'destinationGuide',
	'conditions',
	'links',
	'productListRxjs',
	'productTabsRxjs',
];

/* HYDRATION ON DEMAND  */
export const HeaderBannerSimpleHydrated =
	withHydrationOnDemand<ComponentProps<typeof HeaderBannerSimple>>()(HeaderBannerSimple);
export const SearcherFerryHotelHydrated =
	withHydrationOnDemand<ComponentProps<typeof SearcherFerryHotelMobile>>()(SearcherFerryHotelMobile);
export const SearcherTrainHotelHydrated =
	withHydrationOnDemand<ComponentProps<typeof SearcherTrainHotelMobile>>()(SearcherTrainHotelMobile);
export const SearcherFlightHotelHydrated =
	withHydrationOnDemand<ComponentProps<typeof SearcherFlightHotelMobile>>()(SearcherFlightHotelMobile);
export const SearcherMultiPakHydrated =
	withHydrationOnDemand<ComponentProps<typeof SearcherMultiPak>>()(SearcherMultiPak);
export const SearcherTabsHydrated = withHydrationOnDemand<SearcherTabsProps | ComponentProps<typeof SearcherTabs>>()(
	SearcherTabs
);
export const SearcherTouHydrated = withHydrationOnDemand<ComponentProps<typeof SearcherTouMobile>>()(SearcherTouMobile);
export const BreadcrumbsHydrated = withHydrationOnDemand<ComponentProps<typeof Breadcrumbs>>()(Breadcrumbs);
export const CardsScrollHydrated = withHydrationOnDemand<ComponentProps<typeof CardsScroll>>()(CardsScroll);
export const ComarketingHydrated = withHydrationOnDemand<ComponentProps<typeof Comarketing>>()(Comarketing);
export const LinksSEOHydrated = withHydrationOnDemand<ComponentProps<typeof LinksSEO>>()(LinksSEO);
export const ConditionsHydrated =
	withHydrationOnDemand<ComponentProps<typeof TextHTMLCollapsePackagesMobile>>()(TextHTMLCollapsePackagesMobile);
export const BannersHydrated = withHydrationOnDemand<ComponentProps<typeof Banners>>()(Banners);
export const TabsCarouselProductCardHydrated =
	withHydrationOnDemand<ComponentProps<typeof TabsCarouselProductCard>>()(TabsCarouselProductCard);
export const CarouselProductHydrated = withHydrationOnDemand<ComponentProps<typeof CarouselProduct>>()(CarouselProduct);
export const ProductLeiHydrated = withHydrationOnDemand<ComponentProps<typeof ProductLei>>()(ProductLei);
export const CarouselListHydrated = withHydrationOnDemand<ComponentProps<typeof CarouselList>>()(CarouselList);
export const DestinationGuideHydrated =
	withHydrationOnDemand<ComponentProps<typeof DestinationGuide>>()(DestinationGuide);
export const FeaturesHydrated =
	withHydrationOnDemand<ComponentProps<typeof FeaturesPackagesMobile>>()(FeaturesPackagesMobile);
export const LinksBarHydrated = withHydrationOnDemand<ComponentProps<typeof LinksBar>>()(LinksBar);
export const ImagesListHydrated = withHydrationOnDemand<ComponentProps<typeof ImagesList>>()(ImagesList);
export const ImagetHydrated = withHydrationOnDemand<ComponentProps<typeof Image>>()(Image);
export const CarouselOffersHydrated =
	withHydrationOnDemand<ComponentProps<typeof CarouselOffersCta>>()(CarouselOffersCta);
export const HeaderCarouselHydrated = withHydrationOnDemand<ComponentProps<typeof CarouselHeader>>()(CarouselHeader);
export const CarouselTravelCardHydrated =
	withHydrationOnDemand<ComponentProps<typeof CarouselTravelCard>>()(CarouselTravelCard);
