import { TitleMobileStyled } from '@babylon/ui-kit-base/components/text/title/views/mobile/styled';
import Section from '@babylon/ui-kit-structures/components/others/section';
import { Margin } from '@babylon/ui-kit-styles/common/mixins/logical.styled';
import { SectionContainer } from '@babylon/ui-kit-styles/common/mixins/section.styled';
import styled, { css } from 'styled-components';

export const StyledContainer = styled(SectionContainer)`
	${Margin({ top: '40px' })};
`;

export const TextHtml = styled(Section)`
	${TitleMobileStyled} {
		padding: 0;
	}
`;

export const Features = styled.div`
	${({ theme }) => css`
		& .variant-primary {
			background-color: transparent;
		}
		& h2 {
			& > span {
				color: ${theme.colors.grays.darker};
			}
		}
	`}
`;

export const StyledSectionPayment = styled(SectionContainer)`
	${Margin({ top: '12px', bottom: '0px' })};
`;
export const StyledSectionCta = styled(SectionContainer)`
	${Margin({ top: '36px', bottom: '36px' })};
`;
export const SearcherSection = styled(Section)`
	position: relative;
	${Margin({ x: '16px', top: '-26px' })};
`;
