import CarouselProduct from '@babylon/ui-kit-carousels/components/carousel-product/views/desktop';
import type { FC } from 'react';

import type { ProductLeiProps } from '../../types';
import { converterModelToCarouselProductProps } from '../../utils/converter';

const ProductLeiDesktop: FC<ProductLeiProps> = props => {
	const carouselProductProps = converterModelToCarouselProductProps(props);
	return (
		<div data-testid='ProductLei'>
			<CarouselProduct props={carouselProductProps} />
		</div>
	);
};

export default ProductLeiDesktop;
