import Icon from '@babylon/ui-kit-base/components/icons/icon';
import { FlexMixin, FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import { BorderRadius } from '@babylon/ui-kit-styles/common/mixins/logical.styled';
import styled from 'styled-components';

export const WeatherForecastWrapperDesktop = styled.div`
	background-color: ${({ theme }) => theme.colors.white.base};
	padding: 20px;
	position: relative;
	${FlexMixin({ align: 'center', justify: 'space-between', wrap: true })}
`;

export const WeatherForecastInfo = styled.div`
	${FlexMixin({ direction: 'column' })}
	width: calc(49% - 8px);

	${({ theme }) => theme.media.down('xl')} {
		width: 100%;
	}
`;

export const WeatherForecastTips = styled.ul`
	${FontMixin({ size: 'medium', weight: 'medium', height: 'base' })}
	color: ${({ theme }) => theme.colors.grays.darker};
	margin-bottom: 20px;
	margin-top: 20px;

	& > li {
		${FlexMixin()}
		margin: 8px 0;
	}

	${Icon} {
		${FontMixin({ size: 'medium', weight: 'regular', height: 'small' })}
		color: ${({ theme }) => theme.colors.status.success};
		padding: 4px 8px 0;
	}
`;

export const WeatherForecastCalendar = styled.div`
	${FlexMixin({ align: 'center', wrap: true, justify: 'space-between' })}
	background-color: ${({ theme }) => theme.colors.grays.light};
	margin-top: 16px;
	padding: 8px 8px 0;
	width: 51%;

	${({ theme }) => theme.media.down('xl')} {
		margin-top: 16px;
		width: 100%;
	}
`;

export const WeatherForecastTemperatureDesktop = styled.div`
	${BorderRadius({ all: '4px' })}
	${FlexMixin({ direction: 'column' })}
	background-color: ${({ theme }) => theme.colors.white.base};
	margin-bottom: 8px;
	width: calc(16% - 4px);
	height: 120px;

	.temperature-month {
		${BorderRadius({ topRight: '4px', topLeft: '4px' })}
		${FontMixin({ size: 'base', weight: 'medium', height: 'base' })}
		background-color: ${({ theme }) => theme.colors.primary.lighter};
		color: ${({ theme }) => theme.colors.white.base};
		padding: 12px;
		text-transform: uppercase;
	}
	.temperature-max {
		${FontMixin({ size: 'large', weight: 'medium', height: 'base' })}
		margin: 20px 0 4px;
	}
	.temperature-min {
		${FontMixin({ size: 'medium', weight: 'medium', height: 'base' })}
		color: ${({ theme }) => theme.colors.grays.dark};
	}

	.temperature-month,
	.temperature-max,
	.temperature-min {
		display: block;
		text-align: center;
	}

	${({ theme }) => theme.media.down('xl')} {
		width: calc(8.3% - 4px);
	}

	${({ theme }) => theme.media.down('lg')} {
		width: calc(16% - 4px);
	}
`;
