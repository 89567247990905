import { TabsWrapper } from '@babylon/ui-kit-structures/components/others/tabs/styled';
import styled from 'styled-components';

export const UtilInfoWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.white.base};
	padding: 20px;

	& .util-info-tabs {
		box-shadow: unset;
	}

	${TabsWrapper} {
		border: 0px;
	}
`;
