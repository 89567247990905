import CarouselProduct from '@babylon/ui-kit-carousels/components/carousel-product';
import { genKey } from '@babylon/ui-kit-helpers/keys';
import type { ILink } from '@babylon/ui-kit-types/link';
import type { FC } from 'react';

import type { CarouselListProps } from '../types';

const getLink = (link: ILink | string | undefined): ILink | undefined => {
	if (typeof link === 'string') return { href: link };

	if (typeof link === 'object') return link;

	return undefined;
};

export const CarouselProducts: FC<CarouselListProps['props']> = props => {
	const { items = [], config = { literals: {} } } = props;

	const { literals = {} } = config;

	return (
		<>
			{!!items.length &&
				items.map(carousel => (
					<CarouselProduct
						props={{
							...carousel,
							link: getLink(carousel.link),
							config: { ...carousel.config, literals },
						}}
						key={genKey(carousel)}
					/>
				))}
		</>
	);
};
