import Icon from '@babylon/ui-kit-base/components/icons/icon';
import { nullsToUndefined } from '@babylon/ui-kit-helpers/null';
import type { FC } from 'react';
import { Children } from 'react';

import type { FaqsProps } from '../../../../types';
import * as S from './styled';

const FaqsMobile: FC<FaqsProps> = ({ text, links }) => (
	<S.FaqsWrapperMobile data-testid='FaqsMobile'>
		{text?.title && <S.FaqsTitle tag='h3' content={text.title} />}
		{text?.content && <S.FaqsContent tag='p' content={text.content} />}
		{links?.title && <S.FaqsTitle tag='h3' content={links.title} />}
		<S.FaqsListMobile data-testid='FaqsListMobile'>
			{Children.toArray(
				links?.items?.map(link => (
					<S.FaqsItemsMobile>
						<a {...nullsToUndefined(link)}>
							{link.content} <Icon className='nico-angle-right' />
						</a>
					</S.FaqsItemsMobile>
				))
			)}
		</S.FaqsListMobile>
	</S.FaqsWrapperMobile>
);

export default FaqsMobile;
