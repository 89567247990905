import Icon from '@babylon/ui-kit-base/components/icons/icon';
import InnerMarkdownHTML from '@babylon/ui-kit-base/components/text/inner-markdown-HTML';
import { FlexMixin, FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import { BorderRadius } from '@babylon/ui-kit-styles/common/mixins/logical.styled';
import styled from 'styled-components';

export const WeatherForecastWrapperMobile = styled.div`
	background-color: ${({ theme }) => theme.colors.white.base};
	padding: 20px;
`;

export const WeatherForecastTitle = styled(InnerMarkdownHTML)`
	${FontMixin({ size: 'base', weight: 'bold', height: 'base' })}
	margin-bottom: 4px;
`;

export const WeatherForecastContent = styled(InnerMarkdownHTML)`
	${FontMixin({ size: 'medium', weight: 'light', height: 'base' })}
	color: ${({ theme }) => theme.colors.grays.darker};
	margin-bottom: 4px;
`;

export const WeatherForecastTips = styled.ul`
	& > li {
		${FlexMixin()}
		${FontMixin({ size: 'medium', weight: 'light', height: 'base' })}
		margin: 8px 0;
	}

	${Icon} {
		${FontMixin({ size: 'medium', weight: 'regular', height: 'small' })}
		color: ${({ theme }) => theme.colors.status.success};
		padding: 4px 8px 0;
	}
`;

export const WeatherForecastTemperature = styled.div`
	border-radius: 4px;
	border: 1px solid ${({ theme }) => theme.colors.grays.light};
	height: 128px;
	min-width: 100px;

	.temperature-month {
		${BorderRadius({ topRight: '4px', topLeft: '4px' })}
		${FontMixin({ size: 'base', weight: 'medium', height: 'base' })}
		background-color: ${({ theme }) => theme.colors.primary.lighter};
		color: ${({ theme }) => theme.colors.white.base};
		padding: 12px;
		text-transform: uppercase;
	}
	.temperature-max {
		${FontMixin({ size: 'large', weight: 'medium', height: 'base' })}
		margin: 20px 0 4px;
	}
	.temperature-min {
		${FontMixin({ size: 'medium', weight: 'medium', height: 'base' })}
		color: ${({ theme }) => theme.colors.grays.dark};
	}

	.temperature-month,
	.temperature-max,
	.temperature-min {
		display: block;
		text-align: center;
	}
`;
