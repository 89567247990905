import Image from '@babylon/ui-kit-base/components/multimedia/image';
import InnerMarkdownHTML from '@babylon/ui-kit-base/components/text/inner-markdown-HTML';
import type { IImage } from '@babylon/ui-kit-types/image';
import type { FC } from 'react';

import type { Activity } from '../../types';
import { ParagraphTitle } from '../paragraph';
import * as S from './styled';

const ActivityDetail: FC<Activity> = ({ title, description, image }) => {
	const imageProps: IImage = {
		resize: {
			operation: 'resize',
			suboperation: 'crop',
			width: 315,
			height: 375,
			quality: 85,
		},
		src: '/comun/images/not-available.png',
		alternative: '/comun/images/not-available.png',
		...image,
	};
	return (
		<S.ActivityDetailWrapper data-testid='ActivityDetail'>
			{title && <ParagraphTitle tag='h3' content={title} />}
			<S.ActivityDetailContainer>
				{description && <InnerMarkdownHTML tag='p' content={description} />}
				{image && <Image {...imageProps} />}
			</S.ActivityDetailContainer>
		</S.ActivityDetailWrapper>
	);
};

export default ActivityDetail;
