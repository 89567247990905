import Image from '@babylon/ui-kit-base/components/multimedia/image';
import InnerMarkdownHTML from '@babylon/ui-kit-base/components/text/inner-markdown-HTML';
import { nullsToUndefined } from '@babylon/ui-kit-helpers/null';
import type { IImage } from '@babylon/ui-kit-types/image';
import type { FC } from 'react';

import type { FastGuideProps } from '../../../../types';
import { ParagraphContent } from '../../../paragraph';
import * as S from './styled';

const FastGuideDesktop: FC<FastGuideProps> = ({ subtitle, content, viewMore, image }) => {
	const imageProps: IImage = {
		resize: {
			operation: 'resize',
			suboperation: 'crop',
			width: 624,
			height: 368,
			quality: 85,
		},
		src: '/comun/images/not-available.png',
		alternative: '/comun/images/not-available.png',
		...image,
	};

	return (
		<S.FastGuideWrapper data-testid='FastGuideDesktop'>
			{subtitle && <S.FastGuideTitle tag='p' content={subtitle} />}
			<S.FastGuideContainer>
				<S.FastGuideContent>
					{content && <ParagraphContent tag='p' content={content} />}
					{viewMore && (
						<S.FastGuideLink>
							{viewMore.description && <InnerMarkdownHTML content={viewMore.description} />}
							<S.FastGuideViewMore {...nullsToUndefined(viewMore)}>
								{viewMore.content}
							</S.FastGuideViewMore>
						</S.FastGuideLink>
					)}
				</S.FastGuideContent>
				{image && <Image {...imageProps} />}
			</S.FastGuideContainer>
		</S.FastGuideWrapper>
	);
};

export default FastGuideDesktop;
