import CarouselGeneric from '@babylon/ui-kit-carousels/components/carousel-generic';
import CarouselHeader from '@babylon/ui-kit-carousels/components/carousel-header';
import type { CarouselHeaderProps } from '@babylon/ui-kit-carousels/components/carousel-header/types';
import type { CarouselOffersCtaProps } from '@babylon/ui-kit-carousels/components/carousel-offers-cta/types';
import Breadcrumbs from '@babylon/ui-kit-common/components/others/breadcrumbs';
import type { BreadcrumbsProps } from '@babylon/ui-kit-common/components/others/breadcrumbs/types';
import Comarketing from '@babylon/ui-kit-common/components/others/comarketing';
import type { ComarketingProps } from '@babylon/ui-kit-common/components/others/comarketing/types';
import Features from '@babylon/ui-kit-common/components/others/features';
import type { FeaturesProps } from '@babylon/ui-kit-common/components/others/features/types';
import type { ImagesListProps } from '@babylon/ui-kit-common/components/others/images-list/types';
import withHydrationOnDemand from '@babylon/ui-kit-hocs/hydration-on-demand/withHydrationOnDemand';
import type {
	SearcherCirLiterals,
	SearcherCirProps,
} from '@babylon/ui-kit-searchers/components/searchers/searcher-cir/types';
import SearcherCirDesktop from '@babylon/ui-kit-searchers/components/searchers/searcher-cir/views/desktop';
import { SectionContainer } from '@babylon/ui-kit-styles/common/mixins/section.styled';
import type { IImage } from '@babylon/ui-kit-types/image';
import CarouselList from '@babylon/ui-kit-wrappers/components/circuitos/carousel-list';
import HeaderBannerSimpleCir from '@babylon/ui-kit-wrappers/components/circuitos/header-banner-simple/views/desktop';
import LinksSEO from '@babylon/ui-kit-wrappers/components/circuitos/links-seo';
import type { LinksSeoCirProps } from '@babylon/ui-kit-wrappers/components/circuitos/links-seo/types';
import MasonryCircuits from '@babylon/ui-kit-wrappers/components/circuitos/masonry';
import TabsCarouselProductCardCir from '@babylon/ui-kit-wrappers/components/circuitos/tabs-carousel-product-card';
import TextHTMLCollapseCircuitsDesktop from '@babylon/ui-kit-wrappers/components/circuitos/text-html-collapse/views/desktop';
import type { CarouselListCircuitsProps } from '@babylon/ui-kit-wrappers/helpers/circuitos/carousel-list/desktop/types';
import type { HeaderBannerSimpleCirProps } from '@babylon/ui-kit-wrappers/helpers/circuitos/header-banner-simple/types';
import type { MasonryCircuitsProps } from '@babylon/ui-kit-wrappers/helpers/circuitos/masonry/types';
import type { TabsProductCirProps } from '@babylon/ui-kit-wrappers/helpers/circuitos/tabs-product-cir/types';
import type { TextHtmlCollapseCircuitsProps } from '@babylon/ui-kit-wrappers/helpers/circuitos/text-html-collapse/desktop/types';
import type { ComponentProps, FC } from 'react';

import type { CarouselCategoryProps } from '@/components/carousel-category';
import CarouselCategory from '@/components/carousel-category';
import type { CircuitsAdvantagesProps } from '@/components/circuits-advantages';
import CircuitsAdvantages from '@/components/circuits-advantages';
import CarouselBanner from '@/components/circuits/carousel-banner-campaign';
import type { CarouselBannerCampaignProps } from '@/components/circuits/carousel-banner-campaign/types';
import ComarkertingLei from '@/components/leisure/comarketing';
import { featuresPropsConverter } from '@/components/liverpool/converters';
import type { FeaturesLiverpoolProps } from '@/components/liverpool/converters/type';
import TravelCard from '@/components/travel-card';
import { CarouselOffersCtaHydrated, ImagesListHydrated } from '@/pages/common/desktop';

import type { FlipCardProps } from '../types';
import { CAROUSEL_PROPS } from '../utils/constants';
import * as S from './styled';

const HeaderBannerHydration =
	withHydrationOnDemand<ComponentProps<typeof HeaderBannerSimpleCir>>()(HeaderBannerSimpleCir);
const CarouselBannerHydrated = withHydrationOnDemand<ComponentProps<typeof CarouselBanner>>()(CarouselBanner);
const CircuitsAdvantagesHydrated =
	withHydrationOnDemand<ComponentProps<typeof CircuitsAdvantages>>()(CircuitsAdvantages);
const CarouselHeaderHydrated = withHydrationOnDemand<ComponentProps<typeof CarouselHeader>>()(CarouselHeader);
const SearcherCirHydrated = withHydrationOnDemand<ComponentProps<typeof SearcherCirDesktop>>()(SearcherCirDesktop);
const BreadcrumbsHydrated = withHydrationOnDemand<ComponentProps<typeof Breadcrumbs>>()(Breadcrumbs);
const TabsCarouselHydrated =
	withHydrationOnDemand<ComponentProps<typeof TabsCarouselProductCardCir>>()(TabsCarouselProductCardCir);
const CarouselGenericHydrated = withHydrationOnDemand<ComponentProps<typeof CarouselGeneric>>()(CarouselGeneric);
const MasonryCircuitsHydrated = withHydrationOnDemand<ComponentProps<typeof MasonryCircuits>>()(MasonryCircuits);
const FeaturesHydrated = withHydrationOnDemand<ComponentProps<typeof Features>>()(Features);
const CarouselCategoryHydrated = withHydrationOnDemand<ComponentProps<typeof CarouselCategory>>()(CarouselCategory);
const ComarketingHydrated = withHydrationOnDemand<ComponentProps<typeof Comarketing>>()(Comarketing);
const ComarketingLeiHydrated = withHydrationOnDemand<ComponentProps<typeof ComarkertingLei>>()(ComarkertingLei);
const CarouselListHydrated = withHydrationOnDemand<ComponentProps<typeof CarouselList>>()(CarouselList);
const TextHtmlCollapseHydrated = withHydrationOnDemand<ComponentProps<typeof TextHTMLCollapseCircuitsDesktop>>()(
	TextHTMLCollapseCircuitsDesktop
);
const LinksSEOHydrated = withHydrationOnDemand<ComponentProps<typeof LinksSEO>>()(LinksSEO);

export const renderHeaderCarousel: FC<CarouselHeaderProps> = (carousel: CarouselHeaderProps) => (
	<>{carousel && <CarouselHeaderHydrated {...carousel} />}</>
);

const renderHeaderBanner: FC<HeaderBannerSimpleCirProps> = (headerBanner: HeaderBannerSimpleCirProps) => (
	<>{headerBanner && <HeaderBannerHydration {...headerBanner} />}</>
);

export const renderSearcherCir: FC<SearcherCirProps> = (searcher: SearcherCirProps, config: SearcherCirLiterals) => (
	<S.SearcherSection>{searcher && <SearcherCirHydrated {...searcher} {...config} />}</S.SearcherSection>
);

export const renderBreadcrumbs: FC<BreadcrumbsProps> = (breadcrumbs: BreadcrumbsProps) => (
	<SectionContainer>{breadcrumbs && <BreadcrumbsHydrated {...breadcrumbs} />}</SectionContainer>
);

const renderTabsCarousel: FC<TabsProductCirProps> = (tabsCarousel: TabsProductCirProps) => (
	<SectionContainer>{tabsCarousel && <TabsCarouselHydrated {...tabsCarousel} />}</SectionContainer>
);

//TODO: Change this for the component CarouselTravelCard
const renderTravelCards: FC<any> = (flipsCards: FlipCardProps) => {
	const cards = flipsCards?.props?.items?.length
		? flipsCards.props.items.map(card => <TravelCard key={JSON.stringify(card)} {...card} />)
		: [];

	const carouselProps = {
		heading: {
			title: {
				content: flipsCards?.props?.title ? flipsCards.props.title : '',
			},
		},
		items: cards,
		...CAROUSEL_PROPS,
	};

	return (
		<>
			{cards && (
				<SectionContainer>
					<CarouselGenericHydrated {...carouselProps} />
				</SectionContainer>
			)}
		</>
	);
};

const renderMasonry: FC<MasonryCircuitsProps['masonry']> = (masonry: MasonryCircuitsProps['masonry']) => (
	<>{masonry && <MasonryCircuitsHydrated masonry={masonry} />}</>
);

const renderCircuitsAdvantages: FC<CircuitsAdvantagesProps> = (travelSafe: CircuitsAdvantagesProps) => (
	<>{travelSafe && <CircuitsAdvantagesHydrated {...travelSafe} />}</>
);

const renderFeatures: FC<FeaturesProps> = (features: FeaturesProps) => (
	<S.Features>{features && <FeaturesHydrated {...features} />}</S.Features>
);

const renderCarouselCategory: FC<CarouselCategoryProps> = (carouselCardSimple: CarouselCategoryProps) => (
	<S.StyledContainer>{carouselCardSimple && <CarouselCategoryHydrated {...carouselCardSimple} />}</S.StyledContainer>
);

const renderComarketing: FC<ComarketingProps> = (comarketing: ComarketingProps) => {
	const { items } = comarketing.props;
	if (items.length > 2) {
		return <>{comarketing && <ComarketingLeiHydrated items={items} />}</>;
	} else {
		return <SectionContainer>{comarketing && <ComarketingHydrated {...comarketing} />}</SectionContainer>;
	}
};

const renderCarouselList: FC<CarouselListCircuitsProps> = (carouselListCir: CarouselListCircuitsProps) => (
	<S.StyledContainer>{carouselListCir && <CarouselListHydrated {...carouselListCir} />}</S.StyledContainer>
);

export const renderTextHtmlCollapse: FC<TextHtmlCollapseCircuitsProps> = (
	textHtmlCollapse: TextHtmlCollapseCircuitsProps
) => <SectionContainer>{textHtmlCollapse && <TextHtmlCollapseHydrated {...textHtmlCollapse} />}</SectionContainer>;
export const renderLinksSeo: FC<LinksSeoCirProps> = (linksSeo: LinksSeoCirProps) => (
	<SectionContainer>{linksSeo && <LinksSEOHydrated {...linksSeo} />}</SectionContainer>
);

const renderCarouselBanner: FC<CarouselBannerCampaignProps> = (cardGallery: CarouselBannerCampaignProps) => (
	<SectionContainer>{cardGallery && <CarouselBannerHydrated {...cardGallery} />}</SectionContainer>
);

/* Liverpool components */
const renderCarouselOffers: FC<CarouselOffersCtaProps> = (carouselOffers: CarouselOffersCtaProps) =>
	carouselOffers && (
		<S.StyledSectionContainer>
			<CarouselOffersCtaHydrated {...carouselOffers} />
		</S.StyledSectionContainer>
	);
const renderImagesList: FC<ImagesListProps> = (paymentList: ImagesListProps) =>
	paymentList && (
		<S.StyledSectionContainer>
			<ImagesListHydrated {...paymentList} />
		</S.StyledSectionContainer>
	);
const renderFeaturesLiverpool: FC<
	FeaturesProps | FeaturesProps['props'] | FeaturesLiverpoolProps | FeaturesLiverpoolProps['props']
> = (features: FeaturesProps | FeaturesProps['props'] | FeaturesLiverpoolProps | FeaturesLiverpoolProps['props']) =>
	features && (
		<S.Features>
			<FeaturesHydrated props={featuresPropsConverter(features)} />
		</S.Features>
	);

export const componentDesktopMapping: { [key: string]: FC<any> } = {
	carousel: renderHeaderCarousel,
	headerBanner: props => {
		if (props.props.items.some((item: IImage) => item.title)) {
			return renderHeaderBanner(props);
		} else {
			return renderHeaderCarousel(props);
		}
	},
	searcher: renderSearcherCir,
	breadcrumbs: renderBreadcrumbs,
	tabsCarousel: renderTabsCarousel,
	tabsProductCir: renderTabsCarousel,
	flipCards: renderTravelCards,
	masonry: renderMasonry,
	//Don't touch mansory placeholder. Talk with Back to change it
	mansory: renderMasonry,
	travelSafe: renderCircuitsAdvantages,
	features: renderFeatures,
	carouselCardSimple: renderCarouselCategory,
	comarketingCarousel: renderComarketing,
	comarketing: renderComarketing,
	carouselListCir: renderCarouselList,
	textCutCollapseBox: renderTextHtmlCollapse,
	linksSeo: renderLinksSeo,
	cardGallery: renderCarouselBanner,
	carouselOffers: renderCarouselBanner,
	carouselOffersCta: renderCarouselOffers,
	paymentList: renderImagesList,
	featuresLiverpool: renderFeaturesLiverpool,
};
