import { FlexMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import { BorderRadius } from '@babylon/ui-kit-styles/common/mixins/logical.styled';
import { css } from 'styled-components';

import type { TravelCardStyles } from '../types';

const veciTravelCardStyles: TravelCardStyles = {
	Card: () => css`
		${BorderRadius({ all: '2px' })};
		border: 1px solid ${({ theme }) => theme.colors.grays.lightest};
	`,
	Image: () => css`
		img {
			${BorderRadius({ all: '2px' })};
		}
	`,
	Button: () => css`
		${FlexMixin({ align: 'center' })};

		&:hover {
			background-color: ${({ theme }) => theme.colors.primary.light};
		}
	`,
};

export default veciTravelCardStyles;
