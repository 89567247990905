import { FlexMixin, FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import { Border, BorderRadius, Margin, Padding } from '@babylon/ui-kit-styles/common/mixins/logical.styled';
import { css } from 'styled-components';

import type { TravelCardStyles } from '../types';

const liverpoolTravelCardStyles: TravelCardStyles = {
	Card: () => css`
		${BorderRadius({ all: '8px' })};
	`,
	Image: () => css`
		${BorderRadius({ all: 'unset', topLeft: '8px', topRight: '8px' })};
		height: 240px;
	`,
	Button: () => css`
		${FlexMixin({ align: 'center', justify: 'center' })};
		${FontMixin({ size: 'base', height: 'base' })}
		max-height: unset;
		height: 46px;
		${Padding({ x: '20px', y: '12px' })}
		background-color: ${({ theme }) => theme.colors.white.base};
		color: ${({ theme }) => theme.colors.secondary.base};
		${BorderRadius({ all: '8px' })};
		${({ theme }) => Border({ all: `1px solid ${theme.colors.secondary.base}` })};

		&:hover {
			background-color: ${({ theme }) => theme.colors.secondary.base};
			color: ${({ theme }) => theme.colors.white.base};
			${({ theme }) => Border({ all: `1px solid ${theme.colors.secondary.base}` })};
		}

		${({ theme }) => theme.media.phone.only} {
			height: 44px;
			${Padding({ x: '24x' })}
			color: ${({ theme }) => theme.colors.secondary.base};
			${FontMixin({ size: 'medium', height: 'base', weight: 'semiBold' })}
			${Margin({ top: '16px' })}
		}
	`,
	TravelCardInfo: () => css`
		${Padding({ all: '20px' })}
		height: calc(100% - 240px);
		${({ theme }) =>
			Border({
				x: `1px solid ${theme.colors.grays.lighter}`,
				bottom: `1px solid ${theme.colors.grays.lighter}`,
			})};

		${BorderRadius({ bottomLeft: '8px', bottomRight: '8px' })};

		${({ theme }) => theme.media.phone.only} {
			${Padding({ all: '16px' })}
			${({ theme }) =>
				Border({
					x: `1px solid ${theme.colors.grays.lighter}`,
					bottom: `1px solid ${theme.colors.grays.lighter}`,
				})};

			${BorderRadius({ bottomLeft: '8px', bottomRight: '8px' })};
		}
	`,

	TravelCardTitle: () => css`
		${FontMixin({ height: 'larger', weight: 'semiBold' })}

		${({ theme }) => theme.media.phone.only} {
			${FontMixin({ height: 'base', weight: 'semiBold' })}
			height: unset;
			font-size: 20px;
		}
	`,

	TravelCardDescription: () => css`
		${Margin({ top: '4px' })}
	`,
};

export default liverpoolTravelCardStyles;
