import { nullsToUndefined } from '@babylon/ui-kit-helpers/null';
import type { FC } from 'react';
import { Children } from 'react';

import type { FaqsProps } from '../../../../types';
import { ParagraphContent, ParagraphTitle } from '../../../paragraph';
import * as S from './styled';

const FaqsDesktop: FC<FaqsProps> = ({ text, links }) => (
	<S.FaqsWrapperDesktop data-testid='FaqsDesktop'>
		{text?.title && <ParagraphTitle tag='h3' content={text.title} />}
		{text?.content && <ParagraphContent tag='p' content={text.content} />}
		{links?.title && <ParagraphTitle tag='h3' content={links.title} />}
		<S.FaqsListDesktop data-testid='FaqsListDesktop'>
			{Children.toArray(
				links?.items?.map(link => (
					<li>
						<a {...nullsToUndefined(link)}>{link.content}</a>
					</li>
				))
			)}
		</S.FaqsListDesktop>
	</S.FaqsWrapperDesktop>
);

export default FaqsDesktop;
