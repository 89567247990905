import Icon from '@babylon/ui-kit-base/components/icons/icon';
import ScrollHorizontal from '@babylon/ui-kit-structures/components/others/scroll-horizontal';
import type { FC } from 'react';
import { Children } from 'react';

import type { WeatherForecastProps } from '../../../../types';
import * as S from './styled';

const WeatherForecastMobile: FC<WeatherForecastProps> = ({ description, tips, weather }) => (
	<S.WeatherForecastWrapperMobile data-testid='WeatherForecastMobile'>
		{description?.title && <S.WeatherForecastTitle tag='h3' content={description.title} />}
		{description?.text && <S.WeatherForecastContent tag='p' content={description.text} />}
		<S.WeatherForecastTips data-testid='WeatherForecastTipsMobile'>
			{Children.toArray(
				tips?.map(tip => (
					<li>
						<Icon className='nico-check' />
						{tip.content}
					</li>
				))
			)}
		</S.WeatherForecastTips>

		<ScrollHorizontal>
			{Children.toArray(
				weather?.map(temperature => (
					<S.WeatherForecastTemperature>
						<span className='temperature-month'>{temperature.month}</span>
						<span className='temperature-max'>{temperature.max}</span>
						<span className='temperature-min'>{temperature.min}</span>
					</S.WeatherForecastTemperature>
				))
			)}
		</ScrollHorizontal>
	</S.WeatherForecastWrapperMobile>
);

export default WeatherForecastMobile;
