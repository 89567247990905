import Tabs from '@babylon/ui-kit-structures/components/others/tabs';
import type { Tab } from '@babylon/ui-kit-structures/components/others/tabs/types';
import type { FC } from 'react';
import { useMemo, useState } from 'react';

import type { TopActivitiesProps } from '../../../../types';
import ActivityDetail from '../../../activity-detail';
import * as S from './styled';

const TopActivitiesDesktop: FC<TopActivitiesProps> = ({ items = [] }) => {
	const [selectedTab, setSelectedTab] = useState<number>(0);

	const finalTabs: Tab[] = useMemo(
		() =>
			items?.map((item, index) => ({
				index,
				label: item.title ?? '',
				element: <ActivityDetail {...item} />,
			})),
		[items]
	);

	return (
		<S.TopActivitiesWrapper data-testid='TopActivitiesDesktop'>
			<Tabs
				className='top-activities-tabs'
				selectedTab={selectedTab}
				orientation='vertical'
				onClick={setSelectedTab}
				tabs={finalTabs}
				preRenderTabs={true}
			/>
		</S.TopActivitiesWrapper>
	);
};

export default TopActivitiesDesktop;
