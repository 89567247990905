import type { IImage } from '@babylon/ui-kit-types/image';

export const imageDefault: IImage = {
	resize: {
		operation: 'resize',
		suboperation: 'crop',
		width: 158,
		height: 150,
		quality: 85,
	},
	src: '/comun/images/not-available.png',
	alternative: '/comun/images/not-available.png',
};

export const modalImageDefault: IImage = {
	...imageDefault,
	resize: {
		...imageDefault.resize,
		width: 450,
		height: 200,
	},
};
