import { nullsToUndefined } from '@babylon/ui-kit-helpers/null';
import type { FC } from 'react';

import * as S from './styled';
import type { ButtonViewMoreProps } from './types';

const ButtonViewMore: FC<ButtonViewMoreProps> = ({ link, content }) => (
	<S.ButtonBox data-testid='ButtonViewMore' className='button-view-more'>
		<a {...nullsToUndefined(link)}>{content}</a>
	</S.ButtonBox>
);

export default ButtonViewMore;
