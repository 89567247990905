import type { FC } from 'react';

import { CarouselProducts } from '../../components/CarouselProducts';
import type { CarouselListProps } from '../../types';
import * as S from './styled';

const CarouselList: FC<CarouselListProps> = ({ props }) => (
	<S.List data-testid='CarouselList'>
		<CarouselProducts {...props} />
	</S.List>
);

export * from './styled';
export default CarouselList;
