import JsonLd from '@babylon/ui-kit-base/components/others/json-ld';
import ProductCard from '@babylon/ui-kit-cards/components/cards/product-card';
import CardSkeletonMobile from '@babylon/ui-kit-cards/components/cards/product-card/views/mobile/card-skeleton';
import classNames from 'classnames';
import type { FC } from 'react';
import { Children, useMemo } from 'react';

import type { CarouselProductProps } from '../../types';
import ButtonViewMore from './button-view-more';
import CardBenefits from './card-benefits';
import * as S from './styled';

const CarouselProductMobile: FC<CarouselProductProps> = ({ props }) => {
	const { title, ldjson, items, className = '', link, config, isLoading = false } = props;

	const skeleton = useMemo(() => new Array(5).fill(<CardSkeletonMobile />), []);

	return (
		<>
			{title && <S.CarouselProductTitle className='carousel-title' {...title} />}
			<S.CarouselProductWrapper
				data-testid='CarouselProduct'
				className={classNames({ [className]: !!className })}
			>
				{props.benefits && <CardBenefits {...props.benefits} />}
				{isLoading
					? Children.toArray(skeleton)
					: Children.toArray(
							items.map(item => {
								const literals = { ...item.config?.literals, ...config?.literals };
								return <ProductCard {...item} config={{ ...item.config, literals }} />;
							})
						)}
				{link && !isLoading && (
					<ButtonViewMore link={link} content={config?.literals?.viewMore ?? link.title} />
				)}
				{Children.toArray(ldjson?.scripts?.map(script => <JsonLd structuredData={script} />))}
			</S.CarouselProductWrapper>
		</>
	);
};

export default CarouselProductMobile;
