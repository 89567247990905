import Accordion from '@babylon/ui-kit-structures/components/others/accordion';
import type { FC } from 'react';
import { Children } from 'react';

import type { UtilInfoProps } from '../../../../types';
import * as S from './styled';

const UtilInfoMobile: FC<UtilInfoProps> = ({ items }) => (
	<S.UtilInfoWrapperMobile data-testid='UtilInfoMobile'>
		{Children.toArray(items?.map(item => <Accordion header={item.title} content={item.content} />))}
	</S.UtilInfoWrapperMobile>
);

export default UtilInfoMobile;
