import Tabs from '@babylon/ui-kit-structures/components/others/tabs';
import styled from 'styled-components';

export const DestinationGuideWrapperDesktop = styled.div`
	background-color: ${({ theme }) => theme.bodyBgColor};
`;

export const TabsProductWrapperDesktop = styled(Tabs)`
	background-color: ${({ theme }) => theme.colors.white.base};
`;
