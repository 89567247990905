import { generateViewsComponent } from '@babylon/ui-kit-helpers/views';

import CarouselBannerCampaignDesktop from './views/desktop';
import CarouselBannerCampaignMobile from './views/mobile';

export const CarouselBannerCampaign = generateViewsComponent(
	CarouselBannerCampaignDesktop,
	CarouselBannerCampaignMobile
);

export * from './types';
export default CarouselBannerCampaign;
