import { generateViewsComponent } from '@babylon/ui-kit-helpers/views';

import AdvantagesPackagesDesktop from './views/desktop';
import AdvantagesPackagesMobile from './views/mobile';

export const AdvantagesPackages = generateViewsComponent(AdvantagesPackagesDesktop, AdvantagesPackagesMobile);

export * from './views/desktop/types';
export * from './views/mobile/types';
export default AdvantagesPackages;
