import Banners from '@babylon/ui-kit-banners/components/banners';
import type { MobileBannersProps } from '@babylon/ui-kit-banners/components/banners/types';
import DestinationCard from '@babylon/ui-kit-cards/components/cards/destination-card';
import type { DestinationCardProps } from '@babylon/ui-kit-cards/components/cards/destination-card/types';
import CarouselCardSimpleCircuits from '@babylon/ui-kit-carousels/components/carousel-card-simple';
import type { CarouselCardSimpleProps } from '@babylon/ui-kit-carousels/components/carousel-card-simple/types';
import CarouselGeneric from '@babylon/ui-kit-carousels/components/carousel-generic';
import type { CarouselHeaderProps } from '@babylon/ui-kit-carousels/components/carousel-header/types';
import type { CarouselOffersCtaProps } from '@babylon/ui-kit-carousels/components/carousel-offers-cta/types';
import Blog from '@babylon/ui-kit-common/components/others/blog';
import type { MobileBlogProps } from '@babylon/ui-kit-common/components/others/blog/types';
import Breadcrumbs from '@babylon/ui-kit-common/components/others/breadcrumbs';
import type { BreadcrumbsProps } from '@babylon/ui-kit-common/components/others/breadcrumbs/types';
import Features from '@babylon/ui-kit-common/components/others/features';
import type { FeaturesProps } from '@babylon/ui-kit-common/components/others/features/types';
import type { ImagesListProps } from '@babylon/ui-kit-common/components/others/images-list/types';
import { genKey } from '@babylon/ui-kit-helpers/keys';
import withHydrationOnDemand from '@babylon/ui-kit-hocs/hydration-on-demand/withHydrationOnDemand';
import SearcherCir from '@babylon/ui-kit-searchers/components/searchers/searcher-cir/index';
import type { SearcherCirProps } from '@babylon/ui-kit-searchers/components/searchers/searcher-cir/types';
import Section from '@babylon/ui-kit-structures/components/others/section';
import CarouselList from '@babylon/ui-kit-wrappers/components/circuitos/carousel-list';
import HeaderBannerSimpleCir from '@babylon/ui-kit-wrappers/components/circuitos/header-banner-searcher';
import LinksSEO from '@babylon/ui-kit-wrappers/components/circuitos/links-seo';
import type { LinksSeoCirProps } from '@babylon/ui-kit-wrappers/components/circuitos/links-seo/types';
import MasonryCircuits from '@babylon/ui-kit-wrappers/components/circuitos/masonry';
import TabsCarouselProductCard from '@babylon/ui-kit-wrappers/components/circuitos/tabs-carousel-product-card';
import TextHTMLCollapseCircuitsMobile from '@babylon/ui-kit-wrappers/components/circuitos/text-html-collapse/views/mobile';
import type { CarouselListCircuitsProps } from '@babylon/ui-kit-wrappers/helpers/circuitos/carousel-list/mobile/types';
import type { HeaderBannerSimpleCirProps } from '@babylon/ui-kit-wrappers/helpers/circuitos/header-banner-searcher/types';
import type { MasonryCircuitsProps } from '@babylon/ui-kit-wrappers/helpers/circuitos/masonry/types';
import type { TabsProductCirProps } from '@babylon/ui-kit-wrappers/helpers/circuitos/tabs-product-cir/types';
import type { TextHtmlCollapseCircuitsProps } from '@babylon/ui-kit-wrappers/helpers/circuitos/text-html-collapse/mobile/types';
import cloneDeep from 'clone-deep';
import type { ComponentProps, FC } from 'react';

import type { CircuitsAdvantagesProps } from '@/components/circuits-advantages';
import CircuitsAdvantages from '@/components/circuits-advantages';
import { CarouselOffersHydrated, HeaderCarouselHydrated, ImagesListHydrated } from '@/pages/common/mobile';

import type { DestinationCardList } from '../types';
import * as S from './styled';

const SearcherCirHydrated = withHydrationOnDemand<SearcherCirProps | ComponentProps<typeof SearcherCir>>()(SearcherCir);

const HeaderBannerHydrated =
	withHydrationOnDemand<ComponentProps<typeof HeaderBannerSimpleCir>>()(HeaderBannerSimpleCir);
const BreadcrumbsHydrated = withHydrationOnDemand<ComponentProps<typeof Breadcrumbs>>()(Breadcrumbs);
const CarouselProductHydrated =
	withHydrationOnDemand<ComponentProps<typeof TabsCarouselProductCard>>()(TabsCarouselProductCard);
const CarouselGenericHydrated = withHydrationOnDemand<ComponentProps<typeof CarouselGeneric>>()(CarouselGeneric);
const FeaturesHydrated = withHydrationOnDemand<ComponentProps<typeof Features>>()(Features);
const CarouselCardSimpleCircuitsHydrated =
	withHydrationOnDemand<ComponentProps<typeof CarouselCardSimpleCircuits>>()(CarouselCardSimpleCircuits);
const MasonryHydrated = withHydrationOnDemand<ComponentProps<typeof MasonryCircuits>>()(MasonryCircuits);
const BannersHydrated = withHydrationOnDemand<ComponentProps<typeof Banners>>()(Banners);
const BlogHydrated = withHydrationOnDemand<ComponentProps<typeof Blog>>()(Blog);
const LinksSEOHydrated = withHydrationOnDemand<ComponentProps<typeof LinksSEO>>()(LinksSEO);
const CarouselListHydrated = withHydrationOnDemand<ComponentProps<typeof CarouselList>>()(CarouselList);
const TextHtmlCollapseHydrated =
	withHydrationOnDemand<ComponentProps<typeof TextHTMLCollapseCircuitsMobile>>()(TextHTMLCollapseCircuitsMobile);
export const renderBannerSearcher: FC<HeaderBannerSimpleCirProps> = (mBannerSearcher: HeaderBannerSimpleCirProps) => (
	<HeaderBannerHydrated {...mBannerSearcher} />
);
const CircuitsAdvantagesHydrated =
	withHydrationOnDemand<ComponentProps<typeof CircuitsAdvantages>>()(CircuitsAdvantages);
export const renderBreadcrumbs: FC<BreadcrumbsProps> = (breadcrumbs: BreadcrumbsProps) =>
	breadcrumbs && <BreadcrumbsHydrated {...breadcrumbs} />;

const renderProductCir: FC<TabsProductCirProps> = (mTabsProductCir: TabsProductCirProps) =>
	mTabsProductCir && (
		<Section>
			<CarouselProductHydrated {...mTabsProductCir} />
		</Section>
	);

//TODO: If Liverpool render CarouselTravelCard else renderDestinationsCarousel
const renderDestinationsCarousel: FC<any> = (mCardCampaignList: DestinationCardList) => {
	const { items, title = '' } = mCardCampaignList.props;
	const heading = {
		title: {
			content: title,
		},
		subtitle: {
			content: '',
		},
		link: {
			href: '',
		},
	};
	const cards = items?.map((card: DestinationCardProps) => (
		<DestinationCard key={genKey(card)} variant='column' {...card} titleTag={'p'} />
	));
	const destinationsCarouselProps = {
		heading,
		items: cards,
		viewHorizontal: true,
	};

	return (
		cards && (
			<Section>
				<CarouselGenericHydrated {...destinationsCarouselProps} />
			</Section>
		)
	);
};

const renderFeatures: FC<FeaturesProps> = (features: FeaturesProps) => (
	<Section>{features && <FeaturesHydrated {...features} />}</Section>
);

const renderCarouselCardSimple: FC<CarouselCardSimpleProps> = (carousel: CarouselCardSimpleProps) => (
	<Section>{carousel && <CarouselCardSimpleCircuitsHydrated {...carousel} />}</Section>
);
const renderCarourselSimpleCard: FC<MasonryCircuitsProps> = (masonry: MasonryCircuitsProps) => (
	<Section>{masonry && <MasonryHydrated {...masonry} />}</Section>
);

const renderCircuitsAdvantages: FC<CircuitsAdvantagesProps> = (travelSafe: CircuitsAdvantagesProps) => (
	<Section>{travelSafe && <CircuitsAdvantagesHydrated {...travelSafe} />}</Section>
);

const renderBanners: FC<MobileBannersProps> = (masonry: MobileBannersProps) => (
	<Section>{masonry && <BannersHydrated {...masonry} />}</Section>
);

export const renderBlog: FC<MobileBlogProps> = (blog: MobileBlogProps) => {
	if (!blog?.props?.items) return;
	const finalBlogProps = cloneDeep(blog);
	finalBlogProps.props.items = blog.props.items.map((item, index) => ({ ...{ ...item, featured: index === 0 } }));
	return (
		<Section>
			<BlogHydrated {...finalBlogProps} />
		</Section>
	);
};
const renderCarouselList: FC<CarouselListCircuitsProps> = (carouselListCir: CarouselListCircuitsProps) => (
	<Section>{carouselListCir && <CarouselListHydrated {...carouselListCir} />}</Section>
);

export const renderTextHtmlCollapse: FC<TextHtmlCollapseCircuitsProps> = (
	textHtmlCollapse: TextHtmlCollapseCircuitsProps
) => <S.TextHtml>{textHtmlCollapse && <TextHtmlCollapseHydrated {...textHtmlCollapse} />}</S.TextHtml>;

export const renderLinks: FC<LinksSeoCirProps> = (links: LinksSeoCirProps) =>
	links && (
		<Section>
			<LinksSEOHydrated {...links} />
		</Section>
	);

/* Liverpool renders */
const renderHeaderCarousel: FC<CarouselHeaderProps> = (headerCarousel: CarouselHeaderProps) => (
	<>{headerCarousel && <HeaderCarouselHydrated {...headerCarousel} />}</>
);
const renderCarouselOffers: FC<CarouselOffersCtaProps> = (carouselOffers: CarouselOffersCtaProps) =>
	carouselOffers && (
		<S.StyledSectionCta>
			<CarouselOffersHydrated {...carouselOffers} />
		</S.StyledSectionCta>
	);

const renderImagesList: FC<ImagesListProps> = (paymentList: ImagesListProps) =>
	paymentList && (
		<S.StyledSectionPayment>
			<ImagesListHydrated {...paymentList} />
		</S.StyledSectionPayment>
	);
const renderSearcherCir: FC<SearcherCirProps> = (searcherCir: SearcherCirProps) => (
	<>
		{searcherCir && (
			<S.SearcherSection>
				<SearcherCirHydrated props={{ ...searcherCir.props }} />
			</S.SearcherSection>
		)}
	</>
);

export const componentMobileMapping: { [key: string]: FC<any> } = {
	mBannerSearcher: renderBannerSearcher,
	mBreadcrumbs: renderBreadcrumbs,
	mTabsProductCir: renderProductCir,
	mCardCampaignList: renderDestinationsCarousel,
	mTravelSafe: renderCircuitsAdvantages,
	mFeatures: renderFeatures,
	mMasonry: renderCarouselCardSimple,
	mMansory: renderCarouselCardSimple,
	mCategoryCardList: renderCarourselSimpleCard,
	mCarousel: renderBanners,
	mBannerCarousel: renderBanners,
	mBlog: renderBlog,
	mProductCardT2ListGroup: renderCarouselList,
	mTextScroll: renderTextHtmlCollapse,
	mTabsSeoLink: renderLinks,
	mCarouselOffersCta: renderCarouselOffers,
	mPaymentList: renderImagesList,
	mHeaderCarousel: renderHeaderCarousel,
	mSearcherCir: renderSearcherCir,
};
