import Title from '@babylon/ui-kit-base/components/text/title';
import styled from 'styled-components';

export const CarouselProductTitle = styled(Title)`
	margin: 16px 0;
`;

export const CarouselProductWrapper = styled.div`
	background: ${({ theme }) => theme.bodyBgColor};
	& > :not(.button-view-more) {
		margin-top: 8px;
	}
`;
