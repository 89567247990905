import BannerCampaignHorizontal from '@babylon/ui-kit-banners/components/banner-campaign-horizontal';
import type { BannerCampaignHorizontalProps } from '@babylon/ui-kit-banners/components/banner-campaign-horizontal/types';
import CarouselGeneric from '@babylon/ui-kit-carousels/components/carousel-generic';
import type { FC } from 'react';

import { CAROUSEL_GENERIC_SLIDER_CONFIG } from '../../constants/carouselGenericProps';
import type { CarouselBannerCampaignProps } from '../../types';

const CarouselBannerMobile: FC<CarouselBannerCampaignProps> = ({ props }) => {
	const allItems = [...(props?.items ?? []), ...(props?.carousel ?? []), ...(props.banner ? [props.banner] : [])];
	const carouselGenericProps = {
		heading: {
			title: {
				content: props?.heading?.title ? props.heading?.title : props.title ?? '',
			},
		},
		sliderConfig: {
			...CAROUSEL_GENERIC_SLIDER_CONFIG,
		},
		items: allItems.map((item: BannerCampaignHorizontalProps) => (
			<div key={JSON.stringify(item)}>
				<BannerCampaignHorizontal {...item} />
			</div>
		)),
	};

	return (
		<div data-testid='CarouselBannerDesktop'>
			<CarouselGeneric {...carouselGenericProps} />
		</div>
	);
};

export default CarouselBannerMobile;
