import type {
	DestinationGuideOld,
	DestinationGuideProps,
	FaqsOld,
	FaqsProps,
	FastGuideOld,
	FastGuideProps,
	NewDestinationGuideProps,
	TopActivitiesOld,
	TopActivitiesProps,
	UtilInfoOld,
	UtilInfoProps,
	WeatherForecastOld,
	WeatherForecastProps,
} from '../types';

const converterUtilInfo = (utilInfo?: UtilInfoOld): UtilInfoProps => {
	const { title, component } = utilInfo ?? {};

	const items = component?.tab?.items?.map(({ content }) => ({ title: content?.title, content: content?.text }));
	return { title, items };
};

const converterFastGuide = (fastGuide?: FastGuideOld): FastGuideProps => {
	const { title, component } = fastGuide ?? {};

	return {
		title,
		subtitle: component?.title,
		content: component?.content,
		image: component?.image,
		viewMore: component?.link,
	};
};

const converterTopActivities = (topActivities?: TopActivitiesOld): TopActivitiesProps => {
	const { title, component } = topActivities ?? {};

	const items = component?.tab?.items?.map(({ content }) => ({
		title: content?.title,
		description: content?.text,
		image: content?.image,
	}));

	return {
		title,
		items,
	};
};

const converterWeatherForecast = (weatherForecast?: WeatherForecastOld): WeatherForecastProps => {
	const { title, component } = weatherForecast ?? {};
	const weather = component?.calendar?.map(cal => ({
		month: cal.title,
		min: cal.temperature?.min,
		max: cal.temperature?.max,
	}));

	return {
		title,
		description: component?.description,
		tips: component?.tips,
		weather,
	};
};

const converterWeatherFaqs = (faqs?: FaqsOld): FaqsProps => {
	const { title, component } = faqs ?? {};

	return {
		title,
		text: component?.text,
		links: component?.links,
	};
};

export const converterModel = (rootProps?: DestinationGuideProps): NewDestinationGuideProps | undefined => {
	if (!rootProps) return rootProps;

	const props = 'data' in rootProps ? rootProps.data : rootProps?.props;

	if (props && 'config' in props)
		return {
			...rootProps,
			props,
		};

	const utilInfo = converterUtilInfo(props?.utilInfo);
	const fastGuide = converterFastGuide(props?.fastGuide);
	const topActivities = converterTopActivities(props?.topActivities);
	const weatherForecast = converterWeatherForecast(props?.weatherForecast);
	const faqs = converterWeatherFaqs(props?.faqs);

	return {
		props: {
			title: (props as DestinationGuideOld['props'])?.Title?.title?.content,
			utilInfo,
			fastGuide,
			topActivities,
			weatherForecast,
			faqs,
		},
	};
};
