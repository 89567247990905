import Icon from '@babylon/ui-kit-base/components/icons/icon';
import Image from '@babylon/ui-kit-base/components/multimedia/image';
import ScrollHorizontal from '@babylon/ui-kit-structures/components/others/scroll-horizontal';
import type { FC } from 'react';
import { Children } from 'react';

import * as S from './styled';
import type { AdvantagesPackagesMobileProps } from './types';

export const AdvantagesPackagesMobile: FC<AdvantagesPackagesMobileProps> = ({ comarketing, features }) => (
	<S.AdvantagesPackagesWrapper data-testid='AdvantagesPackagesMobile'>
		<S.AdvantagesPackagesCarousel>
			<ScrollHorizontal>
				{Children.toArray(
					comarketing?.props.items.map(item => (
						<Image
							{...{
								resize: { width: 460, height: 250 },
								width: 460,
								height: 250,
							}}
							{...item}
							isLazy
							data-testid='AdvantagesPackagesMobileImage'
						/>
					))
				)}
			</ScrollHorizontal>
		</S.AdvantagesPackagesCarousel>

		<S.AdvantagesPackagesFeatures>
			<S.AdvantagesPackagesTitle>{features?.props.title}</S.AdvantagesPackagesTitle>

			<S.AdvantagesPackagesFeaturesList>
				{Children.toArray(
					features?.props.items.map(item => (
						<S.AdvantagesPackagesFeaturesItem data-testid='AdvantagesPackagesFeaturesMobileItem'>
							{item.icon && (
								<S.AdvantagesPackagesFeaturesIcon>
									<Icon className={`nico-${item.icon}`} />
								</S.AdvantagesPackagesFeaturesIcon>
							)}
							<S.AdvantagesPackagesFeaturesInner>
								<S.AdvantagesPackagesFeaturesDescription tag={'p'} content={item.content} />
							</S.AdvantagesPackagesFeaturesInner>
						</S.AdvantagesPackagesFeaturesItem>
					))
				)}
			</S.AdvantagesPackagesFeaturesList>
		</S.AdvantagesPackagesFeatures>
	</S.AdvantagesPackagesWrapper>
);

export * from './types';
export * from './styled';
export default AdvantagesPackagesMobile;
