import type { IProductCard } from '@babylon/ui-kit-cards/components/cards/product-card/types';

import type { CarouselProductProps } from '../../carousel-product';
import type { ProductLeiCard, ProductLeiProps } from '../types';

const positiveNumberOrUndefined = (num?: number): number | undefined => {
	if (num && num > 0) return num;
	return undefined;
};

const converterProductLeiCard = (card: ProductLeiCard): IProductCard => {
	const config = card.config as IProductCard['config'];
	const transport = card.transport?.[0] ?? undefined;
	const icons = card.icons?.map(({ icon, description }) => ({ icon, description }));
	const scripts = card.ldjson ? [card.ldjson] : [];

	const rating = positiveNumberOrUndefined(card.rating);
	const reviews = positiveNumberOrUndefined(card.reviews);

	const price: IProductCard['price'] = {
		market_value: positiveNumberOrUndefined(card.price?.market_value),
		value: positiveNumberOrUndefined(card.price?.value),
		from: card.price?.from,
		discount: positiveNumberOrUndefined(card.price?.discount),
		caption: card.price?.caption,
		paxValue: positiveNumberOrUndefined(card.price?.paxValue),
	};

	return {
		config,
		image: card.image,
		transport,
		rating,
		title: card.title,
		subtitle: card.subtitle,
		descriptions: card.descriptions,
		moreInfo: card.moreInfo,
		icons,
		price,
		reviews,
		link: card.link,
		ldjson: { scripts },
	};
};

export const converterModelToCarouselProductProps = (props: ProductLeiProps): CarouselProductProps['props'] => {
	const subTitle = props.title?.subTitle ?? props.title?.subtitle;

	return {
		visibleCardsCount: true,
		config: { ...props.config },
		title: props.title && { ...props.title, subTitle },
		items: props.items?.map(converterProductLeiCard) ?? [],
		link: props?.title?.link,
	};
};
