export const CAROUSEL_PROPS = {
	sliderConfig: {
		perPage: 3,
		perMove: 3,
		breakpoints: {
			[1024]: { perPage: 2, perMove: 2, pagination: true, navigation: false, arrows: false },
		},
	},
};

export const CAROUSEL_PROPS_MOBILE = {
	viewHorizontal: true,
	widthCards: 1.7,
};
