import InnerMarkdownHTML from '@babylon/ui-kit-base/components/text/inner-markdown-HTML';
import { FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import styled from 'styled-components';

export const FastGuideWrapperMobile = styled.div`
	padding: 20px;
	background-color: ${({ theme }) => theme.colors.white.base};
`;

export const FastGuideTitleMobile = styled(InnerMarkdownHTML)`
	${FontMixin({ size: 'large', weight: 'medium', height: 'large' })}
	color: ${({ theme }) => theme.colors.grays.darker};
	margin: 20px 0;
	position: relative;

	&:after {
		background-color: ${({ theme }) => theme.colors.grays.darker};
		bottom: -8px;
		content: '';
		height: 4px;
		left: 0;
		position: absolute;
		width: 40px;
	}
`;

export const FastGuideContent = styled(InnerMarkdownHTML)`
	& > p {
		${FontMixin({ size: 'medium', weight: 'light', height: 'base' })}
		color: ${({ theme }) => theme.colors.grays.darker};
	}
`;
