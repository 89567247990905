import Icon from '@babylon/ui-kit-base/components/icons/icon';
import InnerMarkdownHTML from '@babylon/ui-kit-base/components/text/inner-markdown-HTML';
import { ScrollHorizontalItem } from '@babylon/ui-kit-structures/components/others/scroll-horizontal/styled';
import { FlexMixin, FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import styled from 'styled-components';

export const AdvantagesPackagesWrapper = styled.div`
	${FlexMixin({ direction: 'column' })}
	position: relative;

	${ScrollHorizontalItem} {
		min-width: calc(95% - 8px);
	}
`;

export const AdvantagesPackagesCarousel = styled.section`
	margin: 20px 0;

	img {
		height: auto;
		width: 100%;
	}
`;

export const AdvantagesPackagesFeatures = styled.div`
	width: 100%;
	margin-bottom: 20px;
`;

export const AdvantagesPackagesTitle = styled.h2`
	${FontMixin({ size: 'larger', height: 'larger', weight: 'medium' })}
	color: ${({ theme }) => theme.colors.grays.darker};
	margin-bottom: 12px;
	padding: 0 16px;
	text-align: center;
`;

export const AdvantagesPackagesFeaturesDescription = styled(InnerMarkdownHTML)`
	${FontMixin({ size: 'medium', height: 'base', weight: 'regular' })}
	text-align: left;
`;

export const AdvantagesPackagesFeaturesList = styled.ul`
	padding: 0 16px;
`;

export const AdvantagesPackagesFeaturesItem = styled.li`
	${FlexMixin({ align: 'flex-start' })}
	color: ${({ theme }) => theme.colors.grays.darker};
	margin-bottom: 10px;

	&:last-child {
		margin-bottom: 0;
	}
`;

export const AdvantagesPackagesFeaturesIcon = styled.div`
	${FontMixin({ size: 'medium', height: 'medium', weight: 'regular' })}
	color: ${({ theme }) => theme.colors.grays.darker};
	margin-right: 16px;

	${Icon} {
		color: ${({ theme }) => theme.colors.primary.light};
		font-size: ${({ theme }) => theme.font.size.xl};
	}
`;

export const AdvantagesPackagesFeaturesInner = styled.div`
	${FlexMixin({ direction: 'column' })}
`;
