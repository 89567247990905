import { FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import { BorderRadius } from '@babylon/ui-kit-styles/common/mixins/logical.styled';
import styled from 'styled-components';

export const ButtonBox = styled.div`
	padding: 24px 0;
	margin-top: 0px;
	text-align: center;
	background-color: ${({ theme }) => theme.colors.white.base};
	background: ${({ theme }) => theme.bodyBgColor};

	a {
		${FontMixin({ size: 'medium', weight: 'medium', height: 'small' })};
		${BorderRadius({ all: '4px' })}
		color: ${({ theme }) => theme.colors.primary.light};
		border: 1px solid ${({ theme }) => theme.colors.primary.light};
		background-color: ${({ theme }) => theme.colors.white.base};
		display: inline-block;
		padding: 8px 16px;
		cursor: pointer;
		transition: 0.4s;
		text-transform: uppercase;
		text-align: center;
		vertical-align: middle;
		white-space: nowrap;
		outline: none;
	}
`;
