import CarouselGenericMobile from '@babylon/ui-kit-carousels/components/carousel-generic/views/mobile/index';
import { Title } from '@babylon/ui-kit-carousels/components/carousel-generic/views/mobile/styled';
import { FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import styled from 'styled-components';

export const StyledCarouselGeneric = styled(CarouselGenericMobile)`
	${Title} {
		${FontMixin({ size: 'larger', height: 'larger', weight: 'semiBold' })}
	}
`;
