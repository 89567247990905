import type { BannerCampaignHorizontalProps } from '@babylon/ui-kit-banners/components/banner-campaign-horizontal/types';
import CardScroll from '@babylon/ui-kit-structures/components/others/cards-scroll';
import type { FC } from 'react';

import type { CarouselBannerCampaignProps } from '../../types';

const CarouselBannerMobile: FC<CarouselBannerCampaignProps> = ({ props }) => {
	const carouselProps = {
		title: props?.heading?.title ? props.heading?.title : '',
		items: props?.items?.length
			? props?.items.map((item: BannerCampaignHorizontalProps) => ({
					...item,
					description: item.subtitle,
				}))
			: [],
	};

	return (
		<div data-testid='CarouselBannerMobile'>
			<CardScroll {...carouselProps} />
		</div>
	);
};

export default CarouselBannerMobile;
