import type { FeaturesProps } from '@babylon/ui-kit-common/components/others/features/types';
import _merge from 'lodash/merge';

import type { FeaturesLiverpoolProps } from './type';

// Move to ui-kit
export const featuresPropsConverter = (
	features: FeaturesProps | FeaturesProps['props'] | FeaturesLiverpoolProps | FeaturesLiverpoolProps['props']
) => {
	const props = 'props' in features ? features.props : features;
	const items = Array.isArray(props.features) ? props.features : props.features?.items;

	const liverpoolFeatures = _merge({}, props, {
		title: {
			content: props.title,
		},
		features: {
			items: items?.map(feature => ({
				content: feature.content,
				image: feature.image,
				icon: feature.icon,
				title: feature.title,
			})),
		},
	});

	return liverpoolFeatures;
};
