import InnerMarkdownHTML from '@babylon/ui-kit-base/components/text/inner-markdown-HTML';
import { FlexMixin, FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import { BorderRadius } from '@babylon/ui-kit-styles/common/mixins/logical.styled';
import styled from 'styled-components';

export const FastGuideWrapper = styled.div`
	padding: 20px;
	background-color: ${({ theme }) => theme.colors.white.base};
`;

export const FastGuideTitle = styled(InnerMarkdownHTML)`
	color: ${({ theme }) => theme.colors.primary.light};
	padding: 0 0 0 20px;
	position: relative;
	${FontMixin({ size: 'xl', weight: 'regular', height: 'xl' })}

	&:after {
		background-color: ${({ theme }) => theme.colors.primary.light};
		content: '';
		height: 36px;
		left: 0;
		position: absolute;
		top: 0;
		width: 4px;
	}
`;

export const FastGuideContainer = styled.div`
	${FlexMixin()}
	margin-top: 12px;

	${({ theme }) => theme.media.down('lg')} {
		${FlexMixin({ direction: 'column' })}
	}

	& > figure img {
		width: 100%;
		height: auto;
	}

	& > figure {
		min-width: 40%;
	}
`;

export const FastGuideContent = styled.div`
	padding: 0 16px 16px;
`;

export const FastGuideLink = styled.div`
	${FlexMixin()}
	align-items: center;
	border-top: 1px solid ${({ theme }) => theme.colors.grays.light};
	margin-top: 20px;
	padding-top: 16px;
	${FontMixin({ height: 'base' })}
`;

export const FastGuideViewMore = styled.a`
	${BorderRadius({ all: '4px' })}
	${FontMixin({ size: 'medium', weight: 'medium', height: 'small' })}
	background-color: ${({ theme }) => theme.colors.primary.light};
	color: ${({ theme }) => theme.colors.white.base};
	margin-left: 8px;
	padding: 8px 16px;
	text-align: center;
	text-transform: uppercase;
	vertical-align: middle;
	white-space: nowrap;
`;
