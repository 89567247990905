import type { FC } from 'react';

import CarouselProduct from '@/components/carousel-product/views/mobile';

import type { ProductLeiProps } from '../../types';
import { converterModelToCarouselProductProps } from '../../utils/converter';

const ProductLeiMobile: FC<ProductLeiProps> = props => {
	const carouselProductProps = converterModelToCarouselProductProps(props);

	return (
		<div data-testid='ProductLei'>
			<CarouselProduct props={carouselProductProps} />
		</div>
	);
};

export default ProductLeiMobile;
