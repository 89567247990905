import CarouselGenericDesktop from '@babylon/ui-kit-carousels/components/carousel-generic/views/desktop/index';
import type { FC } from 'react';
import { useTheme } from 'styled-components';

import TravelCard from '@/components/travel-card';

import { CAROUSEL_DESKTOP_PROPS } from '../../constants';
import type { CarouselTravelCardProps } from '../../types';

export const CarouselTravelCardDesktop: FC<CarouselTravelCardProps> = props => {
	const { title, items } = props;
	const cards = items?.length ? items.map(card => <TravelCard key={JSON.stringify(card)} {...card} />) : [];
	const { id } = useTheme();
	const isLiverpool = id === 'partnershipsLiverpool';

	const carouselDesktopProps = {
		heading: {
			title: {
				content: title ?? '',
			},
		},
		items: cards,
		sliderConfig: {
			...CAROUSEL_DESKTOP_PROPS,
			gap: isLiverpool ? 20 : 12,
		},
	};

	return <div data-testid='CarouselTravelCard'>{cards && <CarouselGenericDesktop {...carouselDesktopProps} />}</div>;
};

export default CarouselTravelCardDesktop;
