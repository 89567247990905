import Tabs from '@babylon/ui-kit-structures/components/others/tabs';
import type { Tab } from '@babylon/ui-kit-structures/components/others/tabs/types';
import type { FC } from 'react';
import { useState } from 'react';

import type { UtilInfoProps } from '../../../../types';
import ActivityDetail from '../../../activity-detail';
import * as S from './styled';

const UtilInfoDesktop: FC<UtilInfoProps> = ({ items = [] }) => {
	const [selectedTab, setSelectedTab] = useState<number>(0);

	const finalTabs: Tab[] = items?.map((item, index) => ({
		index,
		label: item.title ?? '',
		element: <ActivityDetail title={item.title} description={item.content} />,
	}));

	return (
		<S.UtilInfoWrapper data-testid='UtilInfoDesktop'>
			<Tabs
				className='util-info-tabs'
				selectedTab={selectedTab}
				orientation='vertical'
				onClick={setSelectedTab}
				tabs={finalTabs}
				preRenderTabs={true}
			/>
		</S.UtilInfoWrapper>
	);
};

export default UtilInfoDesktop;
