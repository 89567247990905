import InnerMarkdownHTML from '@babylon/ui-kit-base/components/text/inner-markdown-HTML';
import { FlexMixin, FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import styled from 'styled-components';

export const FaqsWrapperMobile = styled.div`
	background-color: ${({ theme }) => theme.colors.white.base};
	padding: 20px;
`;

export const FaqsTitle = styled(InnerMarkdownHTML)`
	${FontMixin({ size: 'medium', weight: 'bold', height: 'base' })}
	color: ${({ theme }) => theme.colors.grays.darker};
	margin-bottom: 12px;
`;

export const FaqsContent = styled(InnerMarkdownHTML)`
	${FontMixin({ size: 'small', weight: 'light', height: 'small' })}
	color: ${({ theme }) => theme.colors.grays.darker};
`;

export const FaqsListMobile = styled.ul``;

export const FaqsItemsMobile = styled.li`
	border-top: 1px solid ${({ theme }) => theme.colors.grays.light};

	a {
		${FlexMixin({ justify: 'space-between', align: 'center' })}
		${FontMixin({ size: 'base', weight: 'regular', height: 'large' })}
		color: ${({ theme }) => theme.colors.grays.darker};
		padding: 12px 0;
	}
`;
