import { DEVICES } from '@babylon/ui-kit-context/types';
import type { BaseTemplateLogitravelProps } from '@babylon/ui-kit-layout/components/logitravel/base-template/types';

import type { MainHomeProps } from '@/pages/circuits/home';

export const transformProps = ({ root, context }: any) => {
	const isMobile = context.DeviceClass === DEVICES.mobile;
	if (isMobile) {
		const templateProps: BaseTemplateLogitravelProps = {
			context,
			mHeader: root.props?.header,
			mFooter: root.props?.footer,
		};
		return { templateProps, mainHomeProps: root };
	}
	const typedRoot = root as MainHomeProps;
	const templateProps: BaseTemplateLogitravelProps = {
		context,
		header: typedRoot.props?.header,
		footer: typedRoot.props?.footer,
	};
	return { templateProps, mainHomeProps: typedRoot };
};
