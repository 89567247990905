import type { ProductCardCarouselRxjsCirProps } from '@babylon/ui-kit-wrappers/helpers/circuitos/product-card-carousel-rxjs-cir/types';
import type { ProductCardCirRxjsProps } from '@babylon/ui-kit-wrappers/helpers/circuitos/product-card-cir-rxjs/types';
import cloneDeep from 'clone-deep';
import _merge from 'lodash/merge';

const cardSimpleConverter = (mCategoryCardList: any, config?: { literals?: { from?: string } }) =>
	mCategoryCardList.props?.items?.map((item: any) => {
		const finalItem = item;
		if (item.price && typeof item.price === 'object' && item.price.value !== undefined) {
			finalItem.price = item.price.value;
		}
		return { ...finalItem, config };
	});

const masonryCardConverter = (mCategoryCardList: any, config?: { literals?: { from?: string } }) =>
	mCategoryCardList.props?.items?.map((item: any) => ({ ...item, config }));

const cardSimpleCarouselConverter = ({
	carousel,
	config,
	title,
}: {
	carousel: any;
	config?: { literals?: { from?: string } };
	title?: string;
}) => ({
	props: {
		...carousel.props,
		items: cardSimpleConverter(carousel, config),
		title: { title },
	},
});

const masonryConverter = ({ carousel, config }: { carousel: any; config?: { literals?: { from?: string } } }) => ({
	props: {
		...carousel.props,
		items: masonryCardConverter(carousel, config),
	},
});

export const dataConverter = (data: any) => {
	const props = cloneDeep(data);
	const {
		config,
		mBannerSearcher,
		mTabsProductCir,
		mMasonry,
		mFeatures,
		mProductCardT2ListGroup,
		mMansory,
		mCategoryCardList,
	} = props;

	if (mBannerSearcher) {
		props.mBannerSearcher = _merge(mBannerSearcher, {
			props: {
				searcher: {
					...mBannerSearcher,
					config: {
						...config,
					},
				},
			},
		});
	}

	if (mTabsProductCir) {
		props.mTabsProductCir = _merge(mTabsProductCir, {
			props: {
				config: {
					...config,
				},
				items: mTabsProductCir.props?.items?.map((item: ProductCardCarouselRxjsCirProps['props']) => ({
					...item,
					items: item?.items?.map((i: ProductCardCirRxjsProps['props']) => ({
						...i,
						config: {
							...config,
						},
					})),
				})),
			},
		});
	}

	if (mProductCardT2ListGroup) {
		props.mProductCardT2ListGroup = _merge(mProductCardT2ListGroup, {
			props: {
				...mProductCardT2ListGroup,
				items: mProductCardT2ListGroup.props?.items?.map((item: ProductCardCarouselRxjsCirProps['props']) => ({
					...item,
					config: {
						...config,
					},
					items: item?.items?.map((i: ProductCardCirRxjsProps['props']) => ({
						...i,
						config: {
							...config,
						},
					})),
				})),
			},
		});
	}

	//TODO: Review this condition. Why do we need mFeatures.props && mFeatures.props.items mFeatures.props.title?
	if (mFeatures?.props?.items && mFeatures?.props?.title) {
		props.mFeatures = {
			props: {
				title: {
					content: mFeatures?.props?.title,
				},
				features: {
					items: mFeatures?.props?.items,
				},
			},
		};
	}

	if (mMasonry) {
		const title = mMasonry?.props?.title;
		props.mMasonry = cardSimpleCarouselConverter({ carousel: props.mMasonry, config, title });
	}

	if (mCategoryCardList) {
		props.mCategoryCardList = {
			masonry: masonryConverter({ carousel: props.mCategoryCardList, config }),
		};
	}

	if (mMansory) {
		const title = props.mMansory?.props?.title;
		props.mMansory = cardSimpleCarouselConverter({ carousel: props.mMansory, config, title });
	}

	return { data: props };
};
