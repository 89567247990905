import Image from '@babylon/ui-kit-base/components/multimedia/image';
import Modal from '@babylon/ui-kit-structures/components/others/modal';
import ScrollHorizontal from '@babylon/ui-kit-structures/components/others/scroll-horizontal';
import classNames from 'classnames';
import type { FC } from 'react';
import { Children, useCallback, useState } from 'react';

import type { Activity, TopActivitiesProps } from '../../../../types';
import { imageDefault, modalImageDefault } from './constants';
import * as S from './styled';

const TopActivitiesMobile: FC<TopActivitiesProps> = ({ items, config }) => {
	const [activity, setActivity] = useState<Activity | undefined>(undefined);

	const onClickActivity = useCallback(
		(item: Activity) => () => {
			setActivity(item);
		},
		[]
	);

	const onCloseModal = useCallback(() => {
		setActivity(undefined);
	}, []);

	return (
		<S.TopActivitiesWrapperMobile data-testid='TopActivitiesMobile'>
			<ScrollHorizontal>
				{Children.toArray(
					items?.map(item => (
						<S.TopActivitiesCardMobile
							data-testid='TopActivityMobile'
							className={classNames({ 'is-small': config?.isSmall })}
							onClick={onClickActivity(item)}
						>
							<Image {...imageDefault} {...item.image} />
							{item.title && <S.TopActivitiesTitleMobile tag='h3' content={item.title} />}
						</S.TopActivitiesCardMobile>
					))
				)}
			</ScrollHorizontal>

			<Modal
				visible={!!activity}
				hide={onCloseModal}
				header={activity?.title}
				content={
					<>
						{activity?.image && <Image {...modalImageDefault} {...activity.image} />}
						<S.TopActivitiesDescriptionContainerModal>
							{activity?.title && <S.TopActivitiesTitleModal tag='h3' content={activity.title} />}
							{activity?.description && (
								<S.TopActivitiesDescriptionModal tag='p' content={activity?.description} />
							)}
						</S.TopActivitiesDescriptionContainerModal>
					</>
				}
				variant='fullScreen'
				iconRight='cancel'
			/>
		</S.TopActivitiesWrapperMobile>
	);
};

export default TopActivitiesMobile;
