import { AccordionContent, AccordionHeader } from '@babylon/ui-kit-structures/components/others/accordion/styled';
import { FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import styled from 'styled-components';

export const UtilInfoWrapperMobile = styled.div`
	padding: 0px 20px;
	background-color: ${({ theme }) => theme.colors.white.base};

	${AccordionHeader} {
		${FontMixin({ size: 'base', weight: 'regular', height: 'base' })}
		color: ${({ theme }) => theme.colors.grays.darker};
		padding: 8px 0;
		text-transform: none;
	}

	${AccordionContent} {
		${FontMixin({ size: 'medium', weight: 'light', height: 'base' })}
		color: ${({ theme }) => theme.colors.grays.dark};
	}
`;
