import Image from '@babylon/ui-kit-base/components/multimedia/image';
import { genKey } from '@babylon/ui-kit-helpers/keys';
import { type FC } from 'react';

import type { ComarketingProps } from '../../types';
import { SLIDER_CONFIG } from '../../utils/constants';
import * as S from './styled';

const ComarketingDesktop: FC<ComarketingProps> = props => {
	const { items } = props;
	const cards = items.map(card => <Image key={genKey(card)} {...card} />);

	const comarketingProps = { sliderConfig: SLIDER_CONFIG, items: cards };
	return (
		<S.WhiteSection>
			<S.CarouselWrapper $onlyOneItem={comarketingProps?.items?.length === 1} {...comarketingProps} />
		</S.WhiteSection>
	);
};

export default ComarketingDesktop;
