import Icon from '@babylon/ui-kit-base/components/icons/icon';
import Image from '@babylon/ui-kit-base/components/multimedia/image';
import { Container } from '@babylon/ui-kit-styles/common/mixins/container.styled';
import type { FC } from 'react';
import { Children } from 'react';

import * as S from './styled';
import type { AdvantagesPackagesProps } from './types';

export const AdvantagesPackagesDesktop: FC<AdvantagesPackagesProps> = ({ props: { Title, features, banner } }) => (
	<S.AdvantagesPackagesBox data-testid='AdvantagesPackages'>
		<Container>
			<S.AdvantagesPackagesWrapper>
				<S.AdvantagesPackagesLeft $hasBanner={!!banner}>
					<S.AdvantagesPackagesTitle tag={'h2'} content={Title.title.content} />

					<S.AdvantagesPackagesFeaturesList>
						{Children.toArray(
							features.map(item => (
								<S.AdvantagesPackagesFeaturesItem data-testid='AdvantagesPackagesFeaturesItem'>
									{item.icon && (
										<S.AdvantagesPackagesFeaturesIcon>
											<Icon className={`nico-${item.icon.name}`} />
										</S.AdvantagesPackagesFeaturesIcon>
									)}
									<S.AdvantagesPackagesFeaturesInner>
										<S.AdvantagesPackagesFeaturesTitle>
											{item.title}
										</S.AdvantagesPackagesFeaturesTitle>
										<S.AdvantagesPackagesFeaturesDescription tag={'p'} content={item.content} />
									</S.AdvantagesPackagesFeaturesInner>
								</S.AdvantagesPackagesFeaturesItem>
							))
						)}
					</S.AdvantagesPackagesFeaturesList>
				</S.AdvantagesPackagesLeft>
				{banner && (
					<S.AdvantagesPackagesRight>
						<S.AdvantagesPackagesSlider pagination={false} autoplay={true}>
							{Children.toArray(
								banner.props.items.map(item => (
									<Image
										{...{
											resize: { width: 720, height: 325 },
											width: 720,
											height: 325,
										}}
										{...item}
										isLazy
										data-testid='AdvantagesPackagesBannerImage'
									/>
								))
							)}
						</S.AdvantagesPackagesSlider>
					</S.AdvantagesPackagesRight>
				)}
			</S.AdvantagesPackagesWrapper>
		</Container>
	</S.AdvantagesPackagesBox>
);

export * from './types';
export default AdvantagesPackagesDesktop;
