import Icon from '@babylon/ui-kit-base/components/icons/icon';
import InnerMarkdownHTML from '@babylon/ui-kit-base/components/text/inner-markdown-HTML';
import Slider from '@babylon/ui-kit-structures/components/others/slider';
import { FlexMixin, FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import styled from 'styled-components';

export const AdvantagesPackagesBox = styled.div`
	background-color: ${({ theme }) => theme.colors.white.base};
	padding: 40px 0;
`;

export const AdvantagesPackagesWrapper = styled.div`
	${FlexMixin({ direction: 'column' })}
	padding: 40px 0;
	position: relative;

	${({ theme }) => theme.media.up('xl')} {
		${FlexMixin({ align: 'center', direction: 'row', wrap: true })}
	}
`;

export const AdvantagesPackagesLeft = styled.div<{ $hasBanner: boolean }>`
	margin-bottom: 25px;
	width: 100%;

	${({ theme }) => theme.media.up('xl')} {
		margin-bottom: 0;
		padding-right: 25px;
		width: ${({ $hasBanner }) => ($hasBanner ? '50%' : '100%')};
	}
`;

export const AdvantagesPackagesRight = styled.div`
	width: 100%;

	${({ theme }) => theme.media.up('xl')} {
		width: 50%;
	}
`;

export const AdvantagesPackagesTitle = styled(InnerMarkdownHTML)`
	${FontMixin({ size: 'medium', height: 'medium', weight: 'medium' })}
	color: ${({ theme }) => theme.colors.grays.darker};
	margin-bottom: 20px;
	padding: 0 8px;
	position: relative;
	text-align: center;

	${({ theme }) => theme.media.up('md')} {
		${FontMixin({ size: 'largest', height: 'largest' })}
	}
`;

export const AdvantagesPackagesFeaturesList = styled.div`
	${FlexMixin({ direction: 'column' })}
`;

export const AdvantagesPackagesFeaturesItem = styled.div`
	${FlexMixin({ direction: 'row', wrap: true })}
	margin-bottom: 20px;

	&:last-child {
		margin-bottom: 0;
	}
`;

export const AdvantagesPackagesFeaturesIcon = styled.div`
	${FontMixin({ size: 'medium', height: 'medium', weight: 'regular' })}
	color: ${({ theme }) => theme.colors.grays.darker};

	${Icon} {
		color: ${({ theme }) => theme.colors.primary.light};
		font-size: 50px;
	}
`;

export const AdvantagesPackagesFeaturesInner = styled.div`
	${FlexMixin({ direction: 'column' })}
	margin-left: auto;
	width: calc(100% - 70px);
`;

export const AdvantagesPackagesFeaturesTitle = styled.p`
	${FontMixin({ size: 'base', height: 'base', weight: 'medium' })}
	color: ${({ theme }) => theme.colors.grays.darker};
	margin-bottom: 4px;
	text-align: left;
`;

export const AdvantagesPackagesFeaturesDescription = styled(InnerMarkdownHTML)`
	${FontMixin({ size: 'medium', height: 'base', weight: 'regular' })}
	text-align: left;

	${({ theme }) => theme.media.up('md')} {
		${FontMixin({ size: 'base', height: 'base', weight: 'regular' })}
	}
`;

export const AdvantagesPackagesSlider = styled(Slider)`
	height: 325px;
	position: relative;

	.splide__track {
		height: 100%;
	}

	.splide__slide {
		a,
		figure,
		img {
			height: 100%;
			margin: 0 auto;
			width: auto;

			${({ theme }) => theme.media.up('xl')} {
				max-width: 100%;
				min-width: 100%;
				object-fit: cover;
				object-position: center;
				width: 720px;
			}
		}
	}

	.splide__arrows {
		.splide__arrow {
			color: ${({ theme }) => theme.colors.white.base};
			font-size: 56px;
			height: 56px;
			width: 56px;
			z-index: ${({ theme }) => theme.zIndex.low};

			${({ theme }) => theme.media.up('lg')} {
				color: ${({ theme }) => theme.colors.grays.darker};
			}

			${({ theme }) => theme.media.up('xl')} {
				color: ${({ theme }) => theme.colors.white.base};
			}

			&.splide__arrow--prev {
				border-radius: 0 ${({ theme }) => theme.border.radius.rounded}
					${({ theme }) => theme.border.radius.rounded} 0;
				margin-left: 20px;
			}

			&.splide__arrow--next {
				border-radius: ${({ theme }) => theme.border.radius.rounded} 0 0
					${({ theme }) => theme.border.radius.rounded};
				margin-right: 20px;
			}
		}
	}
`;
