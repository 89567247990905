export const SLIDER_CONFIG = {
	breakpoints: {
		[1400]: { perPage: 2, perMove: 2 },
		[1200]: { perPage: 2, perMove: 2, pagination: true, navigation: false, arrows: false },
		[900]: { perPage: 1, perMove: 1 },
	},
	perPage: 2,
	perMove: 2,
	pagination: false,
	arrows: true,
};
