import type { Tab } from '@babylon/ui-kit-structures/components/others/tabs/types';
import { useMemo, useState } from 'react';

import Faqs from '../components/faqs';
import FastGuide from '../components/fast-guide';
import TabTitle from '../components/tab-title';
import TopActivities from '../components/top-activities';
import UtilInfo from '../components/util-info';
import WeatherForecast from '../components/weather-forecast';
import type { DestinationGuideProps } from '../types';

const useDestinationGuide = (props?: DestinationGuideProps['props']) => {
	const { faqs, fastGuide, topActivities, utilInfo, weatherForecast } = props ?? {};
	const [selectedTab, setSelectedTab] = useState<number>(0);

	const tabs: Tab[] = useMemo(() => {
		const tabs: Tab[] = [];

		let index = 0;
		if (fastGuide?.title) {
			tabs.push({
				index,
				label: <TabTitle title={fastGuide?.title} />,
				element: fastGuide && <FastGuide {...fastGuide} />,
			});
			index++;
		}
		if (topActivities?.title) {
			tabs.push({
				index,
				label: <TabTitle title={topActivities?.title} />,
				element: topActivities && <TopActivities {...topActivities} />,
			});
			index++;
		}

		if (weatherForecast?.title) {
			tabs.push({
				index,
				label: <TabTitle title={weatherForecast?.title} />,
				element: weatherForecast && <WeatherForecast {...weatherForecast} />,
			});
			index++;
		}

		if (utilInfo?.title) {
			tabs.push({
				index,
				label: <TabTitle title={utilInfo?.title} />,
				element: utilInfo && <UtilInfo {...utilInfo} />,
			});
			index++;
		}

		if (faqs?.title) {
			tabs.push({
				index,
				label: <TabTitle title={faqs?.title} />,
				element: faqs && <Faqs {...faqs} />,
			});
			index++;
		}

		return tabs;
	}, [faqs, fastGuide, topActivities, utilInfo, weatherForecast]);

	return { tabs, selectedTab, setSelectedTab };
};

export default useDestinationGuide;
