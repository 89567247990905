import Image from '@babylon/ui-kit-base/components/multimedia/image';
import type { FC } from 'react';

import type { FastGuideProps } from '../../../../types';
import * as S from './styled';

const FastGuideMobile: FC<FastGuideProps> = ({ subtitle, content, image }) => (
	<S.FastGuideWrapperMobile data-testid='FastGuideMobile'>
		{image && <Image {...image} />}
		{subtitle && <S.FastGuideTitleMobile tag='h3' content={subtitle} />}
		{content && <S.FastGuideContent tag='div' content={content} />}
	</S.FastGuideWrapperMobile>
);

export default FastGuideMobile;
