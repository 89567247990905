import CarouselGeneric from '@babylon/ui-kit-carousels/components/carousel-generic/views/desktop';
import Section from '@babylon/ui-kit-structures/components/others/section';
import { Padding } from '@babylon/ui-kit-styles/common/mixins/logical.styled';
import styled from 'styled-components';

export const CarouselWrapper = styled(CarouselGeneric)<{ $onlyOneItem?: boolean }>`
	max-width: 1500px;
	${Padding({ x: '20px' })};
	margin: auto;

	ul {
		${({ $onlyOneItem }) => $onlyOneItem && `display: flex; justify-content: center;`}
	}
`;

export const WhiteSection = styled(Section)`
	background-color: ${({ theme }) => theme.colors.white.base};
	${Padding({ y: '50px' })};
`;
