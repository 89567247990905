import { FlexMixin, FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import styled from 'styled-components';

export const ActivityDetailWrapper = styled.div`
	padding: 20px;
	max-height: 464px;
	min-height: 275px;
	overflow-y: auto;

	&::-webkit-scrollbar {
		background-color: ${({ theme }) => theme.colors.grays.light};
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		background-color: ${({ theme }) => theme.colors.grays.light};
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: ${({ theme }) => theme.colors.grays.base};
		border-radius: 3px;
	}
`;

export const ActivityDetailContainer = styled.div`
	${FlexMixin({ justify: 'space-between' })}

	& > p {
		${FontMixin({ size: 'medium', weight: 'light', height: 'base' })}
		color: ${({ theme }) => theme.colors.grays.darker};
		margin-right: 32px;
	}

	& img {
		min-width: 275px;
	}
`;
