import InnerMarkdownHTML from '@babylon/ui-kit-base/components/text/inner-markdown-HTML';
import { FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import styled from 'styled-components';

export const ParagraphTitle = styled(InnerMarkdownHTML)`
	${FontMixin({ size: 'larger', weight: 'regular', height: 'larger' })}
	color: ${({ theme }) => theme.colors.grays.darker};
	margin-bottom: 20px;
	padding-bottom: 12px;
	position: relative;

	&:after {
		background-color: ${({ theme }) => theme.colors.grays.darker};
		bottom: 0;
		content: '';
		height: 4px;
		left: 0;
		position: absolute;
		width: 40px;
	}
`;

export const ParagraphContent = styled(InnerMarkdownHTML)`
	${FontMixin({ size: 'medium', weight: 'light', height: 'base' })}
	color: ${({ theme }) => theme.colors.grays.darker};
`;
