import Icon from '@babylon/ui-kit-base/components/icons/icon';
import { FlexMixin, FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import styled from 'styled-components';

export const CardBenefitsWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.primary.light};
	margin-top: 8px 0px;
	overflow: hidden;
	position: relative;
	width: 100%;
	${FlexMixin()}
`;

export const Title = styled.div`
	${FlexMixin({ justify: 'center', align: 'center' })}
	${FontMixin({ size: 'largest', weight: 'light', height: 'largest' })}
	color: ${({ theme }) => theme.colors.white.base};
	padding: 0 8px;
	position: relative;
	width: 90px;

	${Icon} {
		z-index: ${({ theme }) => theme.zIndex.low};
	}

	&::after {
		background-color: ${({ theme }) => theme.colors.primary.base};
		content: '';
		height: 100%;
		left: -30px;
		position: absolute;
		top: 0px;
		transform: skewX(-15deg);
		width: 90px;
	}
`;

export const Content = styled.div`
	${FlexMixin({ align: 'center', justify: 'center', wrap: true })}
	color: ${({ theme }) => theme.colors.white.base};
	padding: 16px 20px;
	text-align: center;

	& span {
		${FontMixin({ size: 'small', weight: 'light', height: 'small' })}
		margin-right: 8px;

		&:not(:last-child)::after {
			content: '·';
			margin-left: 8px;
		}
	}
`;
