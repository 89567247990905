import Icon from '@babylon/ui-kit-base/components/icons/icon';
import InnerMarkdownHTML from '@babylon/ui-kit-base/components/text/inner-markdown-HTML';
import type { FC } from 'react';
import { Children } from 'react';

import * as S from './styled';
import type { CardBenefitsProps } from './type';

const CardBenefits: FC<CardBenefitsProps> = ({ items }) => (
	<S.CardBenefitsWrapper data-testid='CardBenefits'>
		<S.Title data-testid='Title'>
			<Icon className='nico-circle-check' />
		</S.Title>
		<S.Content className='card-benefits-content'>
			{Children.toArray(items.map(item => <InnerMarkdownHTML content={item} />))}
		</S.Content>
	</S.CardBenefitsWrapper>
);

export default CardBenefits;
