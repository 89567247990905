import { createThemes } from '@babylon/ui-kit-styles/common/themes';
import { ThemeId } from '@babylon/ui-kit-styles/types/theme';

import liverpoolTravelCardStyles from './styles/liverpool';
import veciTravelCard from './styles/veci';

const travelCardThemes = createThemes({
	[ThemeId.veci]: veciTravelCard,
	[ThemeId.partnershipsLiverpool]: liverpoolTravelCardStyles,
});

export default travelCardThemes;
