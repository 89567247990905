import type { FC } from 'react';

import TravelCard from '@/components/travel-card';

import type { CarouselTravelCardProps } from '../../types';
import * as S from './styled';

export const CarouselTravelCarMobile: FC<CarouselTravelCardProps> = props => {
	const { title, items } = props;
	const cards = items?.length ? items.map(card => <TravelCard key={JSON.stringify(card)} {...card} />) : [];

	const carouselMobileProps = {
		heading: {
			title: {
				content: title ?? '',
			},
		},
		items: cards,
		viewHorizontal: false,
		marginTop: '16px',
	};

	return <div data-testid='CarouselTravelCard'>{cards && <S.StyledCarouselGeneric {...carouselMobileProps} />}</div>;
};

export default CarouselTravelCarMobile;
