import InnerMarkdownHTML from '@babylon/ui-kit-base/components/text/inner-markdown-HTML';
import { FlexMixin, FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import styled from 'styled-components';

export const TopActivitiesWrapperMobile = styled.div`
	padding: 20px;
	background-color: ${({ theme }) => theme.colors.white.base};
`;

export const TopActivitiesCardMobile = styled.div`
	${FlexMixin({ direction: 'column' })}
	background-color: ${({ theme }) => theme.colors.white.base};
	border: 1px solid ${({ theme }) => theme.colors.grays.light};
	height: 100%;
	width: 248px;

	&.is-small {
		width: 158px;
	}
`;

export const TopActivitiesTitleMobile = styled(InnerMarkdownHTML)`
	${FontMixin({ size: 'base', weight: 'regular', height: 'base' })}
	color: ${({ theme }) => theme.colors.grays.darker};
	padding: 8px 12px 20px;
`;

export const TopActivitiesTitleModal = styled(InnerMarkdownHTML)`
	${FontMixin({ size: 'large', weight: 'medium', height: 'largest' })}
	color: ${({ theme }) => theme.colors.grays.darker};
	margin-bottom: 20px;
	position: relative;

	&:after {
		background-color: ${({ theme }) => theme.colors.grays.darker};
		content: '';
		height: 4px;
		left: 0;
		position: absolute;
		top: 34px;
		width: 40px;
	}
`;

export const TopActivitiesDescriptionModal = styled(InnerMarkdownHTML)`
	${FontMixin({ size: 'medium', weight: 'light', height: 'base' })}
	color: ${({ theme }) => theme.colors.grays.darker};
`;

export const TopActivitiesDescriptionContainerModal = styled.div`
	padding: 20px;
`;
