import Title from '@babylon/ui-kit-base/components/text/title';
import type { FC } from 'react';

import useDestinationGuide from '../../hooks/useDestinationGuide';
import type { DestinationGuideProps } from '../../types';
import { converterModel } from '../../utils/converter';
import * as S from './styled';

const DestinationGuideMobile: FC<DestinationGuideProps> = props => {
	const conv = converterModel(props);
	const { tabs, selectedTab, setSelectedTab } = useDestinationGuide(conv?.props);

	return (
		<S.DestinationGuideWrapperMobile data-testid='DestinationGuide'>
			{conv?.props?.title && <Title title={conv?.props?.title} />}
			<S.TabsProductWrapperMobile selectedTab={selectedTab} onClick={setSelectedTab} tabs={tabs} preRenderTabs />
		</S.DestinationGuideWrapperMobile>
	);
};

export * from './styled';
export default DestinationGuideMobile;
