import { Button } from '@babylon/ui-kit-base/components/buttons/button';
import Image from '@babylon/ui-kit-base/components/multimedia/image';
import { FlexMixin, FontMixin } from '@babylon/ui-kit-styles/common/mixins/helpers.styled';
import { BorderRadius, Margin, Padding } from '@babylon/ui-kit-styles/common/mixins/logical.styled';
import styled from 'styled-components';

import travelCardThemes from './themes';

export const TravelCardLink = styled.a`
	width: 100%;
	height: 100%;
`;

export const TravelCardWrapper = styled.div`
	width: 100%;
	height: 100%;

	${travelCardThemes('Card')}
`;

export const TravelCardImage = styled(Image)`
	height: 300px;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		${BorderRadius({ all: '4px' })};
	}
	${travelCardThemes('Image')}
`;

export const TravelCardInfo = styled.div`
	background-color: ${({ theme }) => theme.colors.white.base};
	${Padding({ top: '12px', bottom: '16px', x: '16px' })};
	${BorderRadius({ bottomLeft: '4px', bottomRight: '4px' })};
	${FlexMixin({ direction: 'column', justify: 'space-between' })};
	row-gap: 16px;
	height: calc(100% - 300px);

	${({ theme }) => theme.media.down('sm')} {
		border: 1px solid ${({ theme }) => theme.colors.grays.light};
		${BorderRadius({ all: '2px' })};
		${FlexMixin({ direction: 'column' })};
	}

	${travelCardThemes('TravelCardInfo')}
`;

export const TravelCardTextWrapper = styled.div`
	${FlexMixin({ direction: 'column' })};
`;

export const TravelCardTitle = styled.h3`
	text-align: left;
	${FontMixin({ size: 'larger', weight: 'medium' })}
	line-height: 32px;
	color: ${({ theme }) => theme.colors.grays.darker};

	${({ theme }) => theme.media.down('sm')} {
		${FontMixin({ size: 'larger', weight: 'medium' })}
		height: 32px;
		color: ${({ theme }) => theme.colors.grays.darker};
	}

	${travelCardThemes('TravelCardTitle')}
`;

export const TravelCardDescription = styled.p`
	text-align: left;
	${FontMixin({ size: 'medium', height: 'base', weight: 'regular' })}
	color: ${({ theme }) => theme.colors.grays.darker};

	${({ theme }) => theme.media.down('sm')} {
		${FontMixin({ size: 'medium', height: 'base', weight: 'light' })}
		color: ${({ theme }) => theme.colors.grays.darker};
	}

	${travelCardThemes('TravelCardDescription')}
`;

export const TravelCardButton = styled(Button)`
	max-height: 36px;
	display: flex;
	align-self: flex-end;
	white-space: nowrap;
	text-transform: uppercase;
	${Margin({ left: '8px' })};

	${({ theme }) => theme.media.down('sm')} {
		${FontMixin({ size: 'medium', height: 'base', weight: 'medium' })}
		color: ${({ theme }) => theme.colors.primary.light};
	}

	${travelCardThemes('Button')}
`;
